import dayjs from 'dayjs'
import omit from 'lodash-es/omit'
import demoPostSrc1 from '@/assets/images/demo/demo-post-1.jpg'
import demoPostSrc2 from '@/assets/images/demo/demo-post-2.jpg'
import demoPostSrc3 from '@/assets/images/demo/demo-post-3.jpg'
import demoPostSrc4 from '@/assets/images/demo/demo-post-4.jpg'
import demoPostSrc5 from '@/assets/images/demo/demo-post-5.jpg'
import demoPostSrc6 from '@/assets/images/demo/demo-post-6.jpg'
import demoPostSrc7 from '@/assets/images/demo/demo-post-7.jpg'
import demoPostSrc8 from '@/assets/images/demo/demo-post-8.jpg'
import demoPostSrc9 from '@/assets/images/demo/demo-post-9.jpg'
import demoPostSrc10 from '@/assets/images/demo/demo-post-10.jpg'
import {
  mockBrandInfo6,
  mockBrandInfo7,
  mockBrandInfo8,
  mockKolInfo1,
  mockKolInfo10,
  mockKolInfo2,
  mockKolInfo3,
  mockKolInfo4,
  mockKolInfo5,
  mockKolInfo6,
  mockKolInfo7,
  mockKolInfo8,
  mockKolInfo9,
} from '@/constants/mock-data/kol-info'
import {
  CompetitiveBrandAnalysisKolListChartsResponse,
  CompetitiveBrandAnalysisKolListEffectivenessResponse,
  CompetitiveBrandAnalysisKolListMetricChartsResponse,
  CompetitiveKolStatisticListCharts,
  CompetitiveKolStatisticListResponse,
} from '@/types/schema/api/competitive-brand-analyses'
import {
  CompetitiveMentionAnalysisKolAnalyzeListResponse,
  CompetitiveMentionTopTenPostList,
  FetchCompetitiveKeywordSimilarKolListResponse,
  FetchCompetitiveMentionEffectivenessTrendChartDataResponse,
  FetchCompetitiveMentionKolFollowerChartDataResponse,
  FetchCompetitiveMentionKolPropertyChartDataResponse,
  FetchCompetitiveMentionPostKolListResponse,
  FetchCompetitiveMentionSimilarKolListResponse,
} from '@/types/schema/api/competitive-mention-analyses'
import { CompetitiveAnalysisKolList } from '@/types/schema/competitive-analyses'
import {
  CompetitiveAnalysisCrawlerStatus,
  Hashtag,
} from '@/types/schema/competitive-analyses/competitive-brand-analyses'
import {
  CompetitiveKeywordAnalysis,
  CompetitiveKeywordAnalysisKeyword,
  CompetitiveKeywordAnalysisKeywordList,
  CompetitiveKeywordAnalysisStatisticList,
  CompetitiveKeywordEffectivenessTrendChartDetail,
  CompetitiveKeywordEffectivenessTrendList,
  CompetitiveKeywordTopTenPostList,
  FetchCompetitiveKeywordKolCustomizedTagsNameChartDataResponse,
  FetchCompetitiveKeywordKolFollowerChartDataResponse,
  FetchCompetitiveKeywordPostKolListResponse,
} from '@/types/schema/competitive-analyses/competitive-keyword-analyses'
import {
  CompetitiveMentionAnalysis,
  CompetitiveMentionEffectivenessTrendChartDetail,
} from '@/types/schema/competitive-analyses/competitive-mention-analyses'
import { PlatformShortcode } from '@/utils/convert-platform'
import { Potential } from '@/utils/detail/potential'

const DEMO_COMPETITIVE_ANALYSIS_ID = -1

const demoCrawlerStatus: CompetitiveAnalysisCrawlerStatus = {
  crawler_job_status: null,
  is_triggerable: true,
}

const demoCompetitiveMentionAnalysis: CompetitiveMentionAnalysis = {
  id: DEMO_COMPETITIVE_ANALYSIS_ID,
  name: '競品 ABC 分析',
  competitive_kol_count: 3,
  updated_at: dayjs().format(),
  creator: {
    id: 0,
    name: 'Kolr',
  },
  latest_updated_at: dayjs().format(),
}

const demoCompetitiveBrandAnalysisKolListEffectiveness: CompetitiveBrandAnalysisKolListEffectivenessResponse =
  {
    kol_infos: [
      {
        uuid: mockBrandInfo8.id,
        name: mockBrandInfo8.name,
        starred: false,
        trend_data: [
          {
            key: '2023-11-01',
            from: '2023-11-01T00:00:00Z',
            to: '2023-11-30T23:59:59.999999999Z',
            post_count: 2,
            engagement_count: 3739,
            engagement_rate: '0.03',
            view_rate: '14178.00',
            avg_like_count: '1844',
            avg_comment_count: '25.5',
            avg_view_count: '14178',
            avg_share_count: '0',
            avg_engagement_count: '1869.5',
            reels_engagement_rate: '0.03',
            value_evaluation: '144333.4',
          },
          {
            key: '2023-12-01',
            from: '2023-12-01T00:00:00Z',
            to: '2023-12-31T23:59:59.999999999Z',
            post_count: 24,
            engagement_count: 37861,
            engagement_rate: '0.02',
            view_rate: '8909.25',
            avg_like_count: '1549.625',
            avg_comment_count: '27.9166666666666667',
            avg_view_count: '8909.25',
            avg_share_count: '0',
            avg_engagement_count: '1577.5416666666666667',
            reels_engagement_rate: '0.02',
            value_evaluation: '1405998.6',
          },
          {
            key: '2024-01-01',
            from: '2024-01-01T00:00:00Z',
            to: '2024-01-31T23:59:59.999999999Z',
            post_count: 48,
            engagement_count: 86158,
            engagement_rate: '0.03',
            view_rate: '12667.88',
            avg_like_count: '1766.0416666666666667',
            avg_comment_count: '28.9166666666666667',
            avg_view_count: '12667.875',
            avg_share_count: '0',
            avg_engagement_count: '1794.9583333333333333',
            reels_engagement_rate: '0.03',
            value_evaluation: '3327680',
          },
          {
            key: '2024-02-01',
            from: '2024-02-01T00:00:00Z',
            to: '2024-02-29T23:59:59.999999999Z',
            post_count: 36,
            engagement_count: 110548,
            engagement_rate: '0.05',
            view_rate: '13937.44',
            avg_like_count: '3003.7777777777777778',
            avg_comment_count: '67',
            avg_view_count: '13937.4444444444444444',
            avg_share_count: '0',
            avg_engagement_count: '3070.7777777777777778',
            reels_engagement_rate: '0.05',
            value_evaluation: '4073491.2',
          },
          {
            key: '2024-03-01',
            from: '2024-03-01T00:00:00Z',
            to: '2024-03-31T23:59:59.999999999Z',
            post_count: 39,
            engagement_count: 66521,
            engagement_rate: '0.03',
            view_rate: '12360.69',
            avg_like_count: '1678.2307692307692308',
            avg_comment_count: '27.4358974358974359',
            avg_view_count: '12360.6923076923076923',
            avg_share_count: '0',
            avg_engagement_count: '1705.6666666666666667',
            reels_engagement_rate: '0.03',
            value_evaluation: '2586369.6',
          },
          {
            key: '2024-04-01',
            from: '2024-04-01T00:00:00Z',
            to: '2024-04-30T23:59:59.999999999Z',
            post_count: 21,
            engagement_count: 29746,
            engagement_rate: '0.02',
            view_rate: '11631.90',
            avg_like_count: '1392.8095238095238095',
            avg_comment_count: '23.6666666666666667',
            avg_view_count: '11631.9047619047619048',
            avg_share_count: '0',
            avg_engagement_count: '1416.4761904761904762',
            reels_engagement_rate: '0.02',
            value_evaluation: '1202412.4',
          },
          {
            key: '2024-05-01',
            from: '2024-05-01T00:00:00Z',
            to: '2024-05-31T23:59:59.999999999Z',
            post_count: 15,
            engagement_count: 27833,
            engagement_rate: '0.03',
            view_rate: '7863.67',
            avg_like_count: '1820.6',
            avg_comment_count: '34.9333333333333333',
            avg_view_count: '7863.6666666666666667',
            avg_share_count: '0',
            avg_engagement_count: '1855.5333333333333333',
            reels_engagement_rate: '0.03',
            value_evaluation: '988325.6',
          },
        ],
      },
      {
        uuid: mockBrandInfo7.id,
        name: mockBrandInfo7.name,
        starred: false,
        trend_data: [
          {
            key: '2023-11-01',
            from: '2023-11-01T00:00:00Z',
            to: '2023-11-30T23:59:59.999999999Z',
            post_count: 1,
            engagement_count: 815,
            engagement_rate: '0.01698482781648636',
            view_rate: '0.43208152055740356',
            avg_like_count: '810',
            avg_comment_count: '5',
            avg_view_count: '20733',
            avg_share_count: '0',
            avg_engagement_count: '815',
            reels_engagement_rate: '0.016984828276092',
            value_evaluation: '49957.2',
          },
          {
            key: '2023-12-01',
            from: '2023-12-01T00:00:00Z',
            to: '2023-12-31T23:59:59.999999999Z',
            post_count: 3,
            engagement_count: 3515,
            engagement_rate: '0.02',
            view_rate: '0.00',
            avg_like_count: '1166.3333333333333333',
            avg_comment_count: '5.3333333333333333',
            avg_view_count: '0',
            avg_share_count: '0',
            avg_engagement_count: '1171.6666666666666667',
            reels_engagement_rate: '0.02',
            value_evaluation: '88565.8',
          },
          {
            key: '2024-01-01',
            from: '2024-01-01T00:00:00Z',
            to: '2024-01-31T23:59:59.999999999Z',
            post_count: 2,
            engagement_count: 2796,
            engagement_rate: '0.03',
            view_rate: '0.00',
            avg_like_count: '1395.5',
            avg_comment_count: '2.5',
            avg_view_count: '0',
            avg_share_count: '0',
            avg_engagement_count: '1398',
            reels_engagement_rate: '0.03',
            value_evaluation: '68078.8',
          },
          {
            key: '2024-02-01',
            from: '2024-02-01T00:00:00Z',
            to: '2024-02-29T23:59:59.999999999Z',
            post_count: 4,
            engagement_count: 3396,
            engagement_rate: '0.02',
            view_rate: '4946.75',
            avg_like_count: '845.25',
            avg_comment_count: '3.75',
            avg_view_count: '4946.75',
            avg_share_count: '0',
            avg_engagement_count: '849',
            reels_engagement_rate: '0.02',
            value_evaluation: '113128.6',
          },
          {
            key: '2024-03-01',
            from: '2024-03-01T00:00:00Z',
            to: '2024-03-31T23:59:59.999999999Z',
            post_count: 3,
            engagement_count: 2064,
            engagement_rate: '0.01',
            view_rate: '6717.67',
            avg_like_count: '685',
            avg_comment_count: '3',
            avg_view_count: '6717.6666666666666667',
            avg_share_count: '0',
            avg_engagement_count: '688',
            reels_engagement_rate: '0.01',
            value_evaluation: '80098.6',
          },
          {
            key: '2024-04-01',
            from: '2024-04-01T00:00:00Z',
            to: '2024-04-30T23:59:59.999999999Z',
            post_count: 1,
            engagement_count: 598,
            engagement_rate: '0.01',
            view_rate: '0.00',
            avg_like_count: '598',
            avg_comment_count: '0',
            avg_view_count: '0',
            avg_share_count: '0',
            avg_engagement_count: '598',
            reels_engagement_rate: '0.01',
            value_evaluation: '14232.4',
          },
          {
            key: '2024-05-01',
            from: '2024-05-01T00:00:00Z',
            to: '2024-05-31T23:59:59.999999999Z',
            post_count: 2,
            engagement_count: 1788,
            engagement_rate: '0.02',
            view_rate: '0.00',
            avg_like_count: '892.5',
            avg_comment_count: '1.5',
            avg_view_count: '0',
            avg_share_count: '0',
            avg_engagement_count: '894',
            reels_engagement_rate: '0.02',
            value_evaluation: '43474.8',
          },
        ],
      },
      {
        uuid: mockBrandInfo6.id,
        name: mockBrandInfo6.name,
        starred: false,
        trend_data: [
          {
            key: '2023-11-01',
            from: '2023-11-01T00:00:00Z',
            to: '2023-11-30T23:59:59.999999999Z',
            post_count: 3,
            engagement_count: 4938,
            engagement_rate: '0.04',
            view_rate: '7548.00',
            avg_like_count: '1635.6666666666666667',
            avg_comment_count: '10.3333333333333333',
            avg_view_count: '7548',
            avg_share_count: '0',
            avg_engagement_count: '1646',
            reels_engagement_rate: '0.04',
            value_evaluation: '158736.8',
          },
          {
            key: '2023-12-01',
            from: '2023-12-01T00:00:00Z',
            to: '2023-12-31T23:59:59.999999999Z',
            post_count: 31,
            engagement_count: 38503,
            engagement_rate: '0.03',
            view_rate: '25655.74',
            avg_like_count: '1231.0967741935483871',
            avg_comment_count: '10.9354838709677419',
            avg_view_count: '25655.7419354838709677',
            avg_share_count: '0',
            avg_engagement_count: '1242.032258064516129',
            reels_engagement_rate: '0.03',
            value_evaluation: '2133835.8',
          },
          {
            key: '2024-01-01',
            from: '2024-01-01T00:00:00Z',
            to: '2024-01-31T23:59:59.999999999Z',
            post_count: 31,
            engagement_count: 45648,
            engagement_rate: '0.03',
            view_rate: '22011.45',
            avg_like_count: '1461.032258064516129',
            avg_comment_count: '11.4838709677419355',
            avg_view_count: '22011.4516129032258065',
            avg_share_count: '0',
            avg_engagement_count: '1472.5161290322580645',
            reels_engagement_rate: '0.03',
            value_evaluation: '2150940.2',
          },
          {
            key: '2024-02-01',
            from: '2024-02-01T00:00:00Z',
            to: '2024-02-29T23:59:59.999999999Z',
            post_count: 29,
            engagement_count: 39259,
            engagement_rate: '0.03',
            view_rate: '17367.93',
            avg_like_count: '1342.7586206896551724',
            avg_comment_count: '11',
            avg_view_count: '17367.9310344827586207',
            avg_share_count: '0',
            avg_engagement_count: '1353.7586206896551724',
            reels_engagement_rate: '0.03',
            value_evaluation: '1737371.4',
          },
          {
            key: '2024-03-01',
            from: '2024-03-01T00:00:00Z',
            to: '2024-03-31T23:59:59.999999999Z',
            post_count: 27,
            engagement_count: 39878,
            engagement_rate: '0.03',
            view_rate: '9332.74',
            avg_like_count: '1464.1851851851851852',
            avg_comment_count: '12.7777777777777778',
            avg_view_count: '9332.7407407407407407',
            avg_share_count: '0',
            avg_engagement_count: '1476.962962962962963',
            reels_engagement_rate: '0.03',
            value_evaluation: '1407720',
          },
          {
            key: '2024-04-01',
            from: '2024-04-01T00:00:00Z',
            to: '2024-04-30T23:59:59.999999999Z',
            post_count: 28,
            engagement_count: 45578,
            engagement_rate: '0.04',
            view_rate: '32419.11',
            avg_like_count: '1614.3928571428571429',
            avg_comment_count: '13.3928571428571429',
            avg_view_count: '32419.1071428571428571',
            avg_share_count: '0',
            avg_engagement_count: '1627.7857142857142857',
            reels_engagement_rate: '0.04',
            value_evaluation: '2470635.4',
          },
          {
            key: '2024-05-01',
            from: '2024-05-01T00:00:00Z',
            to: '2024-05-31T23:59:59.999999999Z',
            post_count: 21,
            engagement_count: 26396,
            engagement_rate: '0.03',
            view_rate: '21077.76',
            avg_like_count: '1244.7619047619047619',
            avg_comment_count: '12.1904761904761905',
            avg_view_count: '21077.7619047619047619',
            avg_share_count: '0',
            avg_engagement_count: '1256.9523809523809524',
            reels_engagement_rate: '0.03',
            value_evaluation: '1326451.8',
          },
        ],
      },
    ],
  }

const demoCompetitiveBrandAnalysisKolListCharts: CompetitiveBrandAnalysisKolListChartsResponse =
  {
    follower_count_charts: [
      {
        uuid: mockBrandInfo8.id,
        name: mockBrandInfo8.name,
        starred_kol: false,
        follower_count: 65376,
      },
      {
        uuid: mockBrandInfo7.id,
        name: mockBrandInfo7.name,
        starred_kol: false,
        follower_count: 49009,
      },
      {
        uuid: mockBrandInfo6.id,
        name: mockBrandInfo6.name,
        starred_kol: false,
        follower_count: 41356,
      },
    ],
    follower_count_growth_rate_charts: [
      {
        uuid: mockBrandInfo6.id,
        name: mockBrandInfo6.name,
        starred_kol: false,
        follower_count_growth_rate: 0.10308057069778442,
      },
      {
        uuid: mockBrandInfo8.id,
        name: mockBrandInfo8.name,
        starred_kol: false,
        follower_count_growth_rate: 0.001973201287910342,
      },
      {
        uuid: mockBrandInfo7.id,
        name: mockBrandInfo7.name,
        starred_kol: false,
        follower_count_growth_rate: -0.0072843763045966625,
      },
    ],
    avg_view_rate_charts: [
      {
        uuid: mockBrandInfo7.id,
        name: mockBrandInfo7.name,
        starred_kol: false,
        avg_view_rate: 3.525430609224698,
      },
      {
        uuid: mockBrandInfo6.id,
        name: mockBrandInfo6.name,
        starred_kol: false,
        avg_view_rate: 0.9582475310210014,
      },
      {
        uuid: mockBrandInfo8.id,
        name: mockBrandInfo8.name,
        starred_kol: false,
        avg_view_rate: 0.3475661012184942,
      },
    ],
    avg_engagement_rate_charts: [
      {
        uuid: mockBrandInfo7.id,
        name: mockBrandInfo7.name,
        starred_kol: false,
        avg_engagement_rate: 0.0473688283173172,
      },
      {
        uuid: mockBrandInfo6.id,
        name: mockBrandInfo6.name,
        starred_kol: false,
        avg_engagement_rate: 0.0308800804665731,
      },
      {
        uuid: mockBrandInfo8.id,
        name: mockBrandInfo8.name,
        starred_kol: false,
        avg_engagement_rate: 0.0284603400296681,
      },
    ],
  }

const demoCompetitiveBrandAnalysisKolListMetricCharts: CompetitiveBrandAnalysisKolListMetricChartsResponse =
  [
    {
      uuid: mockBrandInfo6.id,
      name: mockBrandInfo6.name,
      starred_kol: false,
      metric_value: 174,
    },
    {
      uuid: mockBrandInfo7.id,
      name: mockBrandInfo7.name,
      starred_kol: false,
      metric_value: 17,
    },
    {
      uuid: mockBrandInfo8.id,
      name: mockBrandInfo8.name,
      starred_kol: false,
      metric_value: 129,
    },
  ]

const demoStaticHashtags: Hashtag[] = [
  {
    hashtag: '新片上線',
    count: 26,
    engagement_count: 45883,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '按讚',
    count: 20,
    engagement_count: 87908,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '日常分享',
    count: 17,
    engagement_count: 33034,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '文末抽獎',
    count: 16,
    engagement_count: 21505,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '質感生活',
    count: 15,
    engagement_count: 65456,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '美食',
    count: 14,
    engagement_count: 16424,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '美味',
    count: 10,
    engagement_count: 34308,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '吃貨',
    count: 10,
    engagement_count: 16327,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '美食分享',
    count: 6,
    engagement_count: 6947,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '美食日記',
    count: 6,
    engagement_count: 6947,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '美食探險',
    count: 6,
    engagement_count: 6947,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '美食攝影',
    count: 5,
    engagement_count: 16219,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '美食推薦',
    count: 5,
    engagement_count: 7961,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '美食之旅',
    count: 4,
    engagement_count: 35378,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '美食天堂',
    count: 4,
    engagement_count: 8947,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '吃貨日常',
    count: 4,
    engagement_count: 6482,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '幸福餐桌',
    count: 4,
    engagement_count: 6478,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '家庭美食',
    count: 4,
    engagement_count: 3580,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '美食博主',
    count: 4,
    engagement_count: 3397,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '美食製作',
    count: 4,
    engagement_count: 2414,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '廚房樂趣',
    count: 4,
    engagement_count: 2414,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '健康美食',
    count: 4,
    engagement_count: 2414,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '快速食譜',
    count: 3,
    engagement_count: 5167,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '創意料理',
    count: 3,
    engagement_count: 4964,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '美食藝術',
    count: 3,
    engagement_count: 4827,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '美味時光',
    count: 3,
    engagement_count: 4827,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '料理教室',
    count: 3,
    engagement_count: 4827,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '素食料理',
    count: 3,
    engagement_count: 4401,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '自製美食',
    count: 3,
    engagement_count: 4401,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '甜點控',
    count: 3,
    engagement_count: 4230,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '烘焙時光',
    count: 3,
    engagement_count: 3219,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '全球美食',
    count: 3,
    engagement_count: 2722,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '經典美食',
    count: 3,
    engagement_count: 2648,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '街頭美食',
    count: 3,
    engagement_count: 1980,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '海鮮料理',
    count: 3,
    engagement_count: 1980,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '意大利美食',
    count: 2,
    engagement_count: 25023,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '日式料理',
    count: 2,
    engagement_count: 25023,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '韓國美食',
    count: 2,
    engagement_count: 13472,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '泰式料理',
    count: 2,
    engagement_count: 12798,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '法式料理',
    count: 2,
    engagement_count: 12798,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '中華美食',
    count: 2,
    engagement_count: 12798,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '美食打卡',
    count: 2,
    engagement_count: 9694,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '晚餐時間',
    count: 2,
    engagement_count: 9694,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '早餐快樂',
    count: 2,
    engagement_count: 6492,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '午餐推薦',
    count: 2,
    engagement_count: 5898,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '素食美味',
    count: 2,
    engagement_count: 5898,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '美食愛好者',
    count: 2,
    engagement_count: 5722,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '飲品推薦',
    count: 2,
    engagement_count: 4616,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '咖啡時光',
    count: 2,
    engagement_count: 4518,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '茶與點心',
    count: 2,
    engagement_count: 4488,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '餐廳推薦',
    count: 2,
    engagement_count: 4188,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '美食博客',
    count: 2,
    engagement_count: 4138,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '味覺享受',
    count: 2,
    engagement_count: 4110,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '廚房生活',
    count: 2,
    engagement_count: 4110,
    hashtag_id: null,
    hashtag_status: null,
  },
  {
    hashtag: '週末美食',
    count: 2,
    engagement_count: 4110,
    hashtag_id: null,
    hashtag_status: null,
  },
]

const demoCompetitiveBrandKolStatisticListCharts: CompetitiveKolStatisticListCharts =
  {
    follower_percentage_charts: [
      {
        uuid: mockBrandInfo6.id,
        name: mockBrandInfo6.name,
        starred_kol: false,
        follower_percentages: [
          {
            platform: PlatformShortcode.YouTube,
            follower_percentage: 0.4854943753700414,
          },
          {
            platform: PlatformShortcode.Facebook,
            follower_percentage: 0.35126899546082496,
          },
          {
            platform: PlatformShortcode.Instagram,
            follower_percentage: 0.16323662916913362,
          },
        ],
      },
      {
        uuid: mockBrandInfo7.id,
        name: mockBrandInfo7.name,
        starred_kol: false,
        follower_percentages: [
          {
            platform: PlatformShortcode.Facebook,
            follower_percentage: 0.25669609002942334,
          },
          {
            platform: PlatformShortcode.Instagram,
            follower_percentage: 0.7433039099705766,
          },
        ],
      },
      {
        uuid: mockBrandInfo8.id,
        name: mockBrandInfo8.name,
        starred_kol: false,
        follower_percentages: [
          {
            platform: PlatformShortcode.Facebook,
            follower_percentage: 0.6877191306424648,
          },
          {
            platform: PlatformShortcode.Instagram,
            follower_percentage: 0.31228086935753524,
          },
        ],
      },
    ],
    engagement_count_charts: [
      {
        uuid: mockBrandInfo6.id,
        name: mockBrandInfo6.name,
        starred_kol: false,
        engagement_counts: [
          {
            platform: PlatformShortcode.YouTube,
            engagement_count: 127027,
          },
          {
            platform: PlatformShortcode.Facebook,
            engagement_count: 94169,
          },
          {
            platform: PlatformShortcode.Instagram,
            engagement_count: 43406,
          },
        ],
      },
      {
        uuid: mockBrandInfo7.id,
        name: mockBrandInfo7.name,
        starred_kol: false,
        engagement_counts: [
          {
            platform: PlatformShortcode.Facebook,
            engagement_count: 17057,
          },
          {
            platform: PlatformShortcode.Instagram,
            engagement_count: 49167,
          },
        ],
      },
      {
        uuid: mockBrandInfo8.id,
        name: mockBrandInfo8.name,
        starred_kol: false,
        engagement_counts: [
          {
            platform: PlatformShortcode.Facebook,
            engagement_count: 155307,
          },
          {
            platform: PlatformShortcode.Instagram,
            engagement_count: 69676,
          },
        ],
      },
    ],
  }

const demoCompetitiveBrandKolStatisticListTable: CompetitiveKolStatisticListResponse =
  {
    previous_page: null,
    next_page: null,
    total: 3,
    data: [
      {
        starred_kol: false,
        kol_name: mockBrandInfo6.name,
        uuid: mockBrandInfo6.id,
        platforms: [
          {
            platform: 1,
            link_id: 1268,
            platform_name: 'FB粉絲團',
            platform_id: '170637493009609',
            link_url: 'https://www.facebook.com/170637493009609',
          },
          {
            platform: 3,
            link_id: 1269,
            platform_name: 'IG',
            platform_id: 'lachahua.cat',
            link_url: 'https://www.instagram.com/lachahua.cat',
          },
          {
            platform: 4,
            link_id: 127805,
            platform_name: 'TikTok',
            platform_id: 'lachahua',
            link_url: 'https://www.tiktok.com/@lachahua',
          },
        ],
        follower: 384237,
        follower_count_growth_rate: -0.0072677948585454,
        avg_like_count: 1544.1349206349207,
        avg_comment_count: 46.567460317460316,
        avg_share_count: 15.468253968253968,
        avg_view_count: 19951.98275862069,
        view_rate: 0.05192624020753,
        avg_engagement_count: 1606.170634920635,
        engagement_rate: 0.0041801560883534,
        engagement_count: 404755,
        post_count: 252,
      },
      {
        starred_kol: false,
        kol_name: mockBrandInfo7.name,
        uuid: mockBrandInfo7.id,
        platforms: [
          {
            platform: 0,
            link_id: 169967,
            platform_name: 'YouTube',
            platform_id: 'UCt2eJPUFllX47b7RfTtPw2Q',
            link_url:
              'https://www.youtube.com/channel/UCt2eJPUFllX47b7RfTtPw2Q',
          },
          {
            platform: 1,
            link_id: 169968,
            platform_name: 'FB粉絲團',
            platform_id: '112524386896309',
            link_url: 'https://www.facebook.com/112524386896309',
          },
          {
            platform: 3,
            link_id: 169969,
            platform_name: 'IG',
            platform_id: 'uni_catto',
            link_url: 'https://www.instagram.com/uni_catto',
          },
        ],
        follower: 275163,
        follower_count_growth_rate: 0.0860982830076969,
        avg_like_count: 1474.9827160493828,
        avg_comment_count: 24.074074074074073,
        avg_share_count: 3.708641975308642,
        avg_view_count: 44654.38121546961,
        view_rate: 0.162283378272041,
        avg_engagement_count: 1502.7654320987654,
        engagement_rate: 0.0054613644715996,
        engagement_count: 608620,
        post_count: 405,
      },
      {
        starred_kol: false,
        kol_name: mockBrandInfo8.name,
        uuid: mockBrandInfo8.id,
        platforms: [
          {
            platform: 1,
            link_id: 16106,
            platform_name: 'FB粉絲團',
            platform_id: '2057098177835600',
            link_url: 'https://www.facebook.com/2057098177835600',
          },
          {
            platform: 3,
            link_id: 16107,
            platform_name: 'IG',
            platform_id: 'nsfw_cat_bobo',
            link_url: 'https://www.instagram.com/nsfw_cat_bobo',
          },
        ],
        follower: 65493,
        follower_count_growth_rate: -0.0066885066885067,
        avg_like_count: 1309.3529411764705,
        avg_comment_count: 7.5588235294117645,
        avg_share_count: 0.9705882352941176,
        avg_view_count: 98011,
        view_rate: 1.496511077519735,
        avg_engagement_count: 1317.8823529411766,
        engagement_rate: 0.0201224917615803,
        engagement_count: 44808,
        post_count: 34,
      },
    ],
  }

const mentionedBrandA = {
  kol_uuid: mockBrandInfo6.id,
  name: mockBrandInfo6.name,
  starred_kol: false,
  follower_count: 663667,
  order_no: null,
  platform_type_list: [0, 1, 3],
}

const mentionedBrandB = {
  kol_uuid: mockBrandInfo7.id,
  name: mockBrandInfo7.name,
  starred_kol: false,
  follower_count: 812372,
  order_no: null,
  platform_type_list: [1, 3, 4],
}

const mentionedBrandC = {
  kol_uuid: mockBrandInfo8.id,
  name: mockBrandInfo8.name,
  starred_kol: false,
  follower_count: 954260,
  order_no: null,
  platform_type_list: [0, 1, 3, 4, 5],
}

const trendDataDateList = [
  '2023-12-01',
  '2024-01-01',
  '2024-02-01',
  '2024-03-01',
  '2024-04-01',
  '2024-05-01',
] as const

type TrendDataDateListKeys = (typeof trendDataDateList)[number]

const demoCompetitiveMentionedKolList: CompetitiveAnalysisKolList = {
  kols: [mentionedBrandA, mentionedBrandB, mentionedBrandC],
}

const demoCompetitiveMentionEffectivenessTrendChartData: FetchCompetitiveMentionEffectivenessTrendChartDataResponse =
  {
    kol_infos: [
      {
        uuid: mentionedBrandA.kol_uuid,
        name: mentionedBrandA.name,
        trend_data: [
          {
            key: trendDataDateList[0],
            from: '2023-12-01T00:00:00Z',
            to: '2023-12-31T23:59:59.999999999Z',
            social_volume_count: 15246,
            post_count: 3,
            kol_count: 3,
            avg_follower_count: 8084,
            like_count: 227,
            comment_count: 47,
            view_count: 14972,
            share_count: 0,
            engagement_count: 0,
          },
          {
            key: trendDataDateList[1],
            from: '2024-01-01T00:00:00Z',
            to: '2024-01-31T23:59:59.999999999Z',
            social_volume_count: 1380,
            post_count: 2,
            kol_count: 1,
            avg_follower_count: 7271,
            like_count: 1018,
            comment_count: 362,
            view_count: 0,
            share_count: 0,
            engagement_count: 0,
          },
          {
            key: trendDataDateList[2],
            from: '2024-02-01T00:00:00Z',
            to: '2024-02-29T23:59:59.999999999Z',
            social_volume_count: 27,
            post_count: 1,
            kol_count: 1,
            avg_follower_count: 862,
            like_count: 27,
            comment_count: 0,
            view_count: 0,
            share_count: 0,
            engagement_count: 0,
          },
          {
            key: trendDataDateList[3],
            from: '2024-03-01T00:00:00Z',
            to: '2024-03-31T23:59:59.999999999Z',
            social_volume_count: 120764,
            post_count: 6,
            kol_count: 3,
            avg_follower_count: 12330,
            like_count: 2218,
            comment_count: 66,
            view_count: 118480,
            share_count: 0,
            engagement_count: 0,
          },
          {
            key: trendDataDateList[4],
            from: '2024-04-01T00:00:00Z',
            to: '2024-04-30T23:59:59.999999999Z',
            social_volume_count: 22,
            post_count: 2,
            kol_count: 2,
            avg_follower_count: 11712,
            like_count: 21,
            comment_count: 1,
            view_count: 0,
            share_count: 0,
            engagement_count: 0,
          },
          {
            key: trendDataDateList[5],
            from: '2024-05-01T00:00:00Z',
            to: '2024-05-31T23:59:59.999999999Z',
            social_volume_count: 31,
            post_count: 1,
            kol_count: 1,
            avg_follower_count: 3051,
            like_count: 31,
            comment_count: 0,
            view_count: 0,
            share_count: 0,
            engagement_count: 0,
          },
        ],
      },
      {
        uuid: mentionedBrandB.kol_uuid,
        name: mentionedBrandB.name,
        trend_data: [
          {
            key: trendDataDateList[0],
            from: '2023-12-01T00:00:00Z',
            to: '2023-12-31T23:59:59.999999999Z',
            social_volume_count: 18908,
            post_count: 4,
            kol_count: 4,
            avg_follower_count: 26484,
            like_count: 2440,
            comment_count: 53,
            view_count: 16415,
            share_count: 0,
            engagement_count: 0,
          },
          {
            key: trendDataDateList[1],
            from: '2024-01-01T00:00:00Z',
            to: '2024-01-31T23:59:59.999999999Z',
            social_volume_count: 13828,
            post_count: 2,
            kol_count: 2,
            avg_follower_count: 51889,
            like_count: 443,
            comment_count: 19,
            view_count: 13366,
            share_count: 0,
            engagement_count: 0,
          },
          {
            key: trendDataDateList[2],
            from: '2024-02-01T00:00:00Z',
            to: '2024-02-29T23:59:59.999999999Z',
            social_volume_count: 106372,
            post_count: 5,
            kol_count: 3,
            avg_follower_count: 42036,
            like_count: 2365,
            comment_count: 325,
            view_count: 103682,
            share_count: 0,
            engagement_count: 0,
          },
          {
            key: trendDataDateList[3],
            from: '2024-03-01T00:00:00Z',
            to: '2024-03-31T23:59:59.999999999Z',
            social_volume_count: 0,
            post_count: 0,
            kol_count: 0,
            avg_follower_count: 0,
            like_count: 0,
            comment_count: 0,
            view_count: 0,
            share_count: 0,
            engagement_count: 0,
          },
          {
            key: trendDataDateList[4],
            from: '2024-04-01T00:00:00Z',
            to: '2024-04-30T23:59:59.999999999Z',
            social_volume_count: 2364,
            post_count: 1,
            kol_count: 1,
            avg_follower_count: 104620,
            like_count: 2327,
            comment_count: 37,
            view_count: 0,
            share_count: 0,
            engagement_count: 0,
          },
          {
            key: trendDataDateList[5],
            from: '2024-05-01T00:00:00Z',
            to: '2024-05-31T23:59:59.999999999Z',
            social_volume_count: 0,
            post_count: 0,
            kol_count: 0,
            avg_follower_count: 0,
            like_count: 0,
            comment_count: 0,
            view_count: 0,
            share_count: 0,
            engagement_count: 0,
          },
        ],
      },
      {
        uuid: mentionedBrandC.kol_uuid,
        name: mentionedBrandC.name,
        trend_data: [
          {
            key: trendDataDateList[0],
            from: '2023-12-01T00:00:00Z',
            to: '2023-12-31T23:59:59.999999999Z',
            social_volume_count: 0,
            post_count: 0,
            kol_count: 0,
            avg_follower_count: 0,
            like_count: 0,
            comment_count: 0,
            view_count: 0,
            share_count: 0,
            engagement_count: 0,
          },
          {
            key: trendDataDateList[1],
            from: '2024-01-01T00:00:00Z',
            to: '2024-01-31T23:59:59.999999999Z',
            social_volume_count: 0,
            post_count: 0,
            kol_count: 0,
            avg_follower_count: 0,
            like_count: 0,
            comment_count: 0,
            view_count: 0,
            share_count: 0,
            engagement_count: 0,
          },
          {
            key: trendDataDateList[2],
            from: '2024-02-01T00:00:00Z',
            to: '2024-02-29T23:59:59.999999999Z',
            social_volume_count: 0,
            post_count: 0,
            kol_count: 0,
            avg_follower_count: 0,
            like_count: 0,
            comment_count: 0,
            view_count: 0,
            share_count: 0,
            engagement_count: 0,
          },
          {
            key: trendDataDateList[3],
            from: '2024-03-01T00:00:00Z',
            to: '2024-03-31T23:59:59.999999999Z',
            social_volume_count: 8226,
            post_count: 2,
            kol_count: 2,
            avg_follower_count: 398816,
            like_count: 8170,
            comment_count: 56,
            view_count: 0,
            share_count: 0,
            engagement_count: 0,
          },
          {
            key: trendDataDateList[4],
            from: '2024-04-01T00:00:00Z',
            to: '2024-04-30T23:59:59.999999999Z',
            social_volume_count: 0,
            post_count: 0,
            kol_count: 0,
            avg_follower_count: 0,
            like_count: 0,
            comment_count: 0,
            view_count: 0,
            share_count: 0,
            engagement_count: 0,
          },
          {
            key: trendDataDateList[5],
            from: '2024-05-01T00:00:00Z',
            to: '2024-05-31T23:59:59.999999999Z',
            social_volume_count: 0,
            post_count: 0,
            kol_count: 0,
            avg_follower_count: 0,
            like_count: 0,
            comment_count: 0,
            view_count: 0,
            share_count: 0,
            engagement_count: 0,
          },
        ],
      },
    ],
  }

const trendDetailDataDateList: Record<
  TrendDataDateListKeys,
  Partial<CompetitiveMentionEffectivenessTrendChartDetail>
>[] = [
  {
    [trendDataDateList[0]]: {
      post_author_name: 'Foodieeee Food',
      post_id: '1',
      post_title:
        '在家裡舉辦小小的甜點派對，和家人朋友分享手工製作的美點，歡笑聲中感受幸福。',
      post_engagement_count: 180,
      post_thumbnail_url: demoPostSrc1,
    },
    [trendDataDateList[1]]: {
      post_author_name: '美食探險家',
      post_id: '1',
      post_title: '這間餐廳有著獨特的裝潢，氛圍極佳，讓人有賓至如歸的感覺。',
      post_engagement_count: 1609,
      post_thumbnail_url: demoPostSrc2,
    },
    [trendDataDateList[2]]: {
      post_author_name: '王小明吃美食 Xiao Min Foodie',
      post_id: '1',
      post_title:
        '這次的美食之旅，深入品味每一道菜背後新鮮食材的原始風味，每一口都是自然的驚喜。',
      post_engagement_count: 27,
      post_thumbnail_url: demoPostSrc3,
    },
    [trendDataDateList[3]]: {
      post_author_name: '好好料理',
      post_id: '1',
      post_title:
        '太適合當作禮物送出給朋友了，每一口都充滿著主廚的用心，感受到料理的精湛技藝。',
      post_engagement_count: 1141,
      post_thumbnail_url: demoPostSrc4,
    },
    [trendDataDateList[4]]: {
      post_author_name: '廚房奇兵',
      post_id: '1',
      post_title: '菜品不僅美味，擺盤也非常精美，每一道菜都是藝術品。',
      post_engagement_count: 21,
      post_thumbnail_url: demoPostSrc5,
    },
    [trendDataDateList[5]]: {
      post_author_name: 'Joy 喬伊吃吃喝喝',
      post_id: '1',
      post_title:
        '陽光灑在窗外，悠閒地品味一口香醇咖啡，搭配著柔軟的甜點，簡單的下午茶時光。',
      post_engagement_count: 31,
      post_thumbnail_url: demoPostSrc6,
    },
  },
  {
    [trendDataDateList[0]]: {
      post_author_name: '星星去走走',
      post_id: '1',
      post_title: '在工作室裡，擁有一份美味的甜點，讓工作時光更加充實而美好。',
      post_engagement_count: 1698,
      post_thumbnail_url: demoPostSrc7,
    },
    [trendDataDateList[1]]: {
      post_author_name: '味蕾旅行家',
      post_id: '1',
      post_title: '清爽的椰子冰淇淋，帶你體驗熱帶風情。',
      post_engagement_count: 444,
      post_thumbnail_url: demoPostSrc8,
    },
    [trendDataDateList[2]]: {
      post_author_name: '花兒 Flower🌸',
      post_id: '1',
      post_title:
        '靜靜地坐在窗邊，聽著雨聲，一口一口品味著甜點，這是最好的心靈慰藉。',
      post_engagement_count: 1608,
      post_thumbnail_url: demoPostSrc9,
    },
    [trendDataDateList[3]]: {
      //無資料
      post_author_name: '',
      post_id: '1',
      post_title: '',
      post_engagement_count: 0,
      post_thumbnail_url: '',
    },
    [trendDataDateList[4]]: {
      post_author_name: 'Bda 帶你吃吃喝喝',
      post_id: '1',
      post_title:
        '走進街角小巷，發現了一家隱藏的甜品店，每一口都是驚奇的味覺冒險。',
      post_engagement_count: 2364,
      post_thumbnail_url: demoPostSrc10,
    },
    [trendDataDateList[5]]: {
      //無資料
      post_author_name: '',
      post_id: '1',
      post_title: '',
      post_engagement_count: 0,
      post_thumbnail_url: '',
    },
  },
  {
    [trendDataDateList[0]]: {
      //無資料
      post_author_name: '',
      post_id: '1',
      post_title: '',
      post_engagement_count: 0,
      post_thumbnail_url: '',
    },
    [trendDataDateList[1]]: {
      //無資料
      post_author_name: '',
      post_id: '1',
      post_title: '',
      post_engagement_count: 0,
      post_thumbnail_url: '',
    },
    [trendDataDateList[2]]: {
      //無資料
      post_author_name: '',
      post_id: '1',
      post_title: '',
      post_engagement_count: 0,
      post_thumbnail_url: '',
    },
    [trendDataDateList[3]]: {
      post_author_name: '芽米ㄉ頻道',
      post_id: '1',
      post_title:
        '早晨的陽光，一杯咖啡搭配著柔軟的甜點，為一天帶來美好的開始。',
      post_engagement_count: 8222,
      post_thumbnail_url: demoPostSrc1,
    },
    [trendDataDateList[4]]: {
      //無資料
      post_author_name: '',
      post_id: '1',
      post_title: '',
      post_engagement_count: 0,
      post_thumbnail_url: '',
    },
    [trendDataDateList[5]]: {
      //無資料
      post_author_name: '',
      post_id: '1',
      post_title: '',
      post_engagement_count: 0,
      post_thumbnail_url: '',
    },
  },
]

const getDemoCompetitiveMentionedEfficientTrendDetail = (
  kolUuid: string,
  trendDataDate: string,
): CompetitiveMentionEffectivenessTrendChartDetail => {
  const postInfoRecord = {
    [mentionedBrandA.kol_uuid]: trendDetailDataDateList[0],
    [mentionedBrandB.kol_uuid]: trendDetailDataDateList[1],
    [mentionedBrandC.kol_uuid]: trendDetailDataDateList[2],
  }

  return {
    kol_uuid: '',
    kol_name: '',
    follower_count: 0,
    post_author_uuid: '',
    post_url: '',
    post_crawler_id: '',
    post_type: '',
    post_like_count: 0,
    post_comment_count: 0,
    post_view_count: 0,
    post_share_count: 0,
    post_social_volume_count: 1680,
    ...postInfoRecord[kolUuid]?.[trendDataDate],
  }
}

const demoCompetitiveMentionedKolFollowerChartData: FetchCompetitiveMentionKolFollowerChartDataResponse =
  {
    follower_percentage_charts: [
      {
        uuid: mentionedBrandA.kol_uuid,
        name: mentionedBrandA.name,
        starred_kol: false,
        follower_level_percentages: [
          {
            follower_level: 'level1',
            percentage: 0.1111111111111111,
          },
          {
            follower_level: 'level2',
            percentage: 0.5555555555555556,
          },
          {
            follower_level: 'level3',
            percentage: 0.3333333333333333,
          },
          {
            follower_level: 'level4',
            percentage: 0,
          },
          {
            follower_level: 'level5',
            percentage: 0,
          },
        ],
      },
      {
        uuid: mentionedBrandB.kol_uuid,
        name: mentionedBrandB.name,
        starred_kol: false,
        follower_level_percentages: [
          {
            follower_level: 'level1',
            percentage: 0.6363636363636364,
          },
          {
            follower_level: 'level2',
            percentage: 0.36363636363636365,
          },
          {
            follower_level: 'level3',
            percentage: 0,
          },
          {
            follower_level: 'level4',
            percentage: 0,
          },
          {
            follower_level: 'level5',
            percentage: 0,
          },
        ],
      },
      {
        uuid: mentionedBrandC.kol_uuid,
        name: mentionedBrandC.name,
        starred_kol: false,
        follower_level_percentages: [
          {
            follower_level: 'level1',
            percentage: 0,
          },
          {
            follower_level: 'level2',
            percentage: 0.5,
          },
          {
            follower_level: 'level3',
            percentage: 0,
          },
          {
            follower_level: 'level4',
            percentage: 0.5,
          },
          {
            follower_level: 'level5',
            percentage: 0,
          },
        ],
      },
    ],
  }

const demoCompetitiveMentionKolPropertyChartData: FetchCompetitiveMentionKolPropertyChartDataResponse =
  {
    mention_kol_property_charts: [
      {
        customized_tags_name: 'Food',
        competitive_kol_percentage: 0.7083333333333334,
        starred_kol_percentage: null,
      },
      {
        customized_tags_name: 'Daily Topics',
        competitive_kol_percentage: 0.3333333333333333,
        starred_kol_percentage: null,
      },
      {
        customized_tags_name: 'Education',
        competitive_kol_percentage: 0.2916666666666667,
        starred_kol_percentage: null,
      },
      {
        customized_tags_name: 'Food/Beverage',
        competitive_kol_percentage: 0.25,
        starred_kol_percentage: null,
      },
      {
        customized_tags_name: 'Daily Topics/Holidays',
        competitive_kol_percentage: 0.20833333333333334,
        starred_kol_percentage: null,
      },
      {
        customized_tags_name: 'Food/Dessert',
        competitive_kol_percentage: 0.20833333333333334,
        starred_kol_percentage: null,
      },
      {
        customized_tags_name: 'Art and Entertainment',
        competitive_kol_percentage: 0.16666666666666666,
        starred_kol_percentage: null,
      },
      {
        customized_tags_name: 'Travel',
        competitive_kol_percentage: 0.16666666666666666,
        starred_kol_percentage: null,
      },
      {
        customized_tags_name: 'Fortunetelling',
        competitive_kol_percentage: 0.08333333333333333,
        starred_kol_percentage: null,
      },
      {
        customized_tags_name: 'Travel/Taiwan Travel',
        competitive_kol_percentage: 0.08333333333333333,
        starred_kol_percentage: null,
      },
    ],
  }

const demoCompetitiveMentionSimilarKolList: FetchCompetitiveMentionSimilarKolListResponse =
  {
    competitive_mention_similar_kols: [
      {
        kol_uuid: mockKolInfo1.id,
        name: '吃遍天下小旅行家',
        follower_count: 311531,
        customized_tags_names: [
          'Art and Entertainment',
          'Daily Topics',
          'Family',
          'Sports',
        ],
      },
      {
        kol_uuid: mockKolInfo2.id,
        name: '大廚在這ㄦ',
        follower_count: 175992,
        customized_tags_names: [
          'Beauty and Fashion',
          'Daily Topics',
          'Education',
          'Travel',
          'Daily Topics/Celebrity News',
        ],
      },
      {
        kol_uuid: mockKolInfo3.id,
        name: '美食獵人小蕾',
        follower_count: 143557,
        customized_tags_names: [
          'Gaming/Live Streaming',
          'Beauty and Fashion',
          'Daily Topics',
          'Food',
          'Beauty and Fashion/Outfit',
        ],
      },
      {
        kol_uuid: mockKolInfo4.id,
        name: '搭飛機吃美食的米蘭達',
        follower_count: 140932,
        customized_tags_names: [
          'Daily Topics',
          'Education',
          'Family',
          'Medical and Health',
          'Travel',
        ],
      },
      {
        kol_uuid: mockKolInfo5.id,
        name: '旅食探險家莉莉',
        follower_count: 106034,
        customized_tags_names: [
          'Sports/Basketball',
          'Media Entertainment/K-pop',
          'Sports/Yoga',
          'Daily Topics',
          'Education',
          'Sports',
        ],
      },
      {
        kol_uuid: mockKolInfo6.id,
        name: '咬一口旅行姐妹',
        follower_count: 104013,
        customized_tags_names: [
          'Art and Entertainment',
          'Daily Topics',
          'Education',
        ],
      },
      {
        kol_uuid: mockKolInfo7.id,
        name: '地球美食尋味者小娜',
        follower_count: 98017,
        customized_tags_names: [
          'Relationship',
          'Relationship/Wedding',
          'Education',
          'Family',
          'Medical and Health',
        ],
      },
      {
        kol_uuid: mockKolInfo8.id,
        name: '美食探索家王子',
        follower_count: 96700,
        customized_tags_names: [
          'Relationship',
          'Beauty and Fashion',
          'Daily Topics',
          'Fortunetelling',
          'Photography',
        ],
      },
      {
        kol_uuid: mockKolInfo9.id,
        name: '飄洋過海吃大餐的艾莉絲',
        follower_count: 87181,
        customized_tags_names: [
          'Beauty and Fashion',
          'Daily Topics',
          'Beauty and Fashion/Outfit',
        ],
      },
      {
        kol_uuid: mockKolInfo10.id,
        name: '開飛機的廚神瑪可',
        follower_count: 85466,
        customized_tags_names: [
          'Gaming/Mobile Game',
          'Daily Topics',
          'Family',
          'Pets',
        ],
      },
    ],
  }

const mentionedTopTenRestNonDisplayProps = {
  url: '',
  crawler_id: '',
  platform_type: PlatformShortcode.Instagram,
  is_advertorial: false,
  is_post_collected: false,
  social_volume_count: 8222,
  like_count: 8170,
  comment_count: 52,
  share_count: 0,
  view_count: 0,
  play_count: 0,
}

const demoCompetitiveMentionedTopTenPostList: CompetitiveMentionTopTenPostList =
  [
    {
      type: 'image',
      post_id: 'C4VpsdGSTTZ',
      ig_post_id: '3320743675063514329',
      thumbnail_url: demoPostSrc1,
      title: '',
      content: '早晨的陽光，一杯咖啡搭配著柔軟的甜點，為一天帶來美好的開始。',
      is_disable_like: false,
      published_at: '2024-03-10T15:11:56Z',
      mention_kols: [
        {
          uuid: mentionedBrandC.kol_uuid,
          name: mentionedBrandC.name,
          follower_count: 954260,
        },
      ],
      kol_uuid: '544fcd48-930b-49b4-a1c6-dc0acf99287d',
      kol_name: '芽米ㄉ頻道',
      kol_potential: Potential.Normal,
      kol_customized_tags_names: [
        'Art and Entertainment',
        'Beauty and Fashion',
        'Daily Topics',
        'Daily Topics/Holidays',
      ],
      engagement_count: 8222,
      ...mentionedTopTenRestNonDisplayProps,
    },
    {
      type: 'image',
      post_id: 'C502yUVvZdO',
      ig_post_id: '3347541375368140622',
      thumbnail_url: demoPostSrc2,
      title: '',
      content:
        '走進街角小巷，發現了一家隱藏的甜點店，每一口都是驚奇的味覺冒險。',
      published_at: '2024-04-16T14:34:10Z',
      is_disable_like: false,
      mention_kols: [
        {
          uuid: mentionedBrandB.kol_uuid,
          name: mentionedBrandB.name,
          follower_count: 663667,
        },
      ],
      engagement_count: 2364,
      kol_uuid: mentionedBrandB.kol_uuid,
      kol_name: mentionedBrandB.name,
      kol_potential: Potential.Normal,
      kol_customized_tags_names: ['Education', 'Food', 'Food/Beverage'],
      ...mentionedTopTenRestNonDisplayProps,
    },
    {
      type: 'image',
      post_id: 'C1CZ07xPi4b',
      ig_post_id: '3261282668922154523',
      is_disable_like: false,
      thumbnail_url: demoPostSrc3,
      title: '',
      content: '在工作室裡，擁有一份美味的甜點，讓工作時光更加充實而美好。',
      published_at: '2023-12-19T14:13:31Z',
      mention_kols: [
        {
          uuid: mentionedBrandB.kol_uuid,
          name: mentionedBrandB.name,
          follower_count: 663667,
        },
      ],
      kol_uuid: 'cf98f3dc-6332-430d-906c-be1195a88544',
      kol_name: '星星去走走',
      kol_potential: Potential.Normal,
      kol_customized_tags_names: [
        'Art and Entertainment/Podcast',
        'Food/Dessert',
        'Daily Topics',
        'Education',
        'Family',
        'Food',
        'Pets',
        'Beauty and Fashion/Outfit',
      ],
      engagement_count: 1698,
      ...mentionedTopTenRestNonDisplayProps,
    },
    {
      type: 'short',
      post_id: 'C3pNnfYvei8',
      ig_post_id: '3308235289645672636',
      is_disable_like: false,
      thumbnail_url: demoPostSrc4,
      title: '',
      content:
        '靜靜地坐在窗邊，聽著雨聲，一口一口品味著甜點，這是最好的心靈慰藉。',
      published_at: '2024-02-22T09:00:22Z',
      mention_kols: [
        {
          uuid: mentionedBrandB.kol_uuid,
          name: mentionedBrandB.name,
          follower_count: 663667,
        },
      ],
      kol_uuid: 'c025e021-312b-433a-a140-951db7cea82b',
      kol_name: '花兒 Flower🌸',
      kol_potential: Potential.Normal,
      kol_customized_tags_names: [
        'Art and Entertainment',
        'Food',
        'Medical and Health',
        'Travel',
        'Daily Topics/Holidays',
        'Food/Beverage',
        'Travel/Taiwan Travel',
      ],
      engagement_count: 1613,
      ...mentionedTopTenRestNonDisplayProps,
    },
    {
      type: 'short',
      post_id: 'C47BwTMPHPd',
      ig_post_id: '3331264066558391261',
      is_disable_like: false,
      thumbnail_url: demoPostSrc5,
      title: '',
      content:
        '太適合當作禮物送出給朋友了，每一口都充滿著主廚的用心，感受到料理的精湛技藝。',
      published_at: '2024-03-25T03:36:49Z',
      mention_kols: [
        {
          uuid: mentionedBrandA.kol_uuid,
          name: mentionedBrandA.name,
          follower_count: 812372,
        },
      ],
      kol_uuid: '0229c1e0-a5ab-4b57-b4f4-bc7aefa46594',
      kol_name: '好好料理',
      kol_potential: Potential.Normal,
      kol_customized_tags_names: ['Food', 'Fortunetelling', 'Law and Society'],
      engagement_count: 1141,
      ...mentionedTopTenRestNonDisplayProps,
    },
    {
      type: 'short',
      post_id: 'C47BwTMPHPd',
      ig_post_id: '3331264066558391261',
      is_disable_like: false,
      thumbnail_url: demoPostSrc6,
      title: '',
      content:
        '這間餐廳有著獨特的裝潢，氛圍極佳，主廚熱情，讓人有賓至如歸的感覺。',
      published_at: '2024-03-25T03:36:49Z',
      mention_kols: [
        {
          uuid: mentionedBrandA.kol_uuid,
          name: mentionedBrandA.name,
          follower_count: 812372,
        },
      ],
      kol_uuid: '0229c1e0-a5ab-4b57-b4f4-bc7aefa46594',
      kol_name: '美食探險家',
      kol_potential: Potential.Normal,
      kol_customized_tags_names: ['Food', 'Fortunetelling', 'Law and Society'],
      engagement_count: 690,
      ...mentionedTopTenRestNonDisplayProps,
    },
    {
      type: 'image',
      post_id: 'C1zVH97vOxs',
      ig_post_id: '3275054260542041196',
      is_disable_like: false,
      thumbnail_url: demoPostSrc7,
      title: '',
      content:
        '走出音樂會的場地，發現附近有一家美味的甜點店，為音樂之夜劃下完美的句點。',
      published_at: '2024-01-07T14:15:13Z',
      mention_kols: [
        {
          uuid: mentionedBrandB.kol_uuid,
          name: mentionedBrandB.name,
          follower_count: 812372,
        },
      ],
      kol_uuid: '2dcc4382-b4ab-41bd-9ec4-6782749408f8',
      kol_name: '雨天窩在窗邊',
      kol_potential: Potential.Normal,
      kol_customized_tags_names: ['Education', 'Food'],
      engagement_count: 493,
      ...mentionedTopTenRestNonDisplayProps,
    },
    {
      type: 'image',
      post_id: 'C1zVH97vOxs',
      ig_post_id: '3275054260542041196',
      is_disable_like: false,
      thumbnail_url: demoPostSrc8,
      title: '',
      content:
        '用心做的蛋糕，帶來的不僅是味覺的享受，還有愛的溫暖，甜點帶來慰藉。',
      published_at: '2024-01-07T14:15:13Z',
      mention_kols: [
        {
          uuid: mentionedBrandA.kol_uuid,
          name: mentionedBrandA.name,
          follower_count: 812372,
        },
      ],
      kol_uuid: '2dcc4382-b4ab-41bd-9ec4-6782749408f8',
      kol_name: '甜點地圖',
      kol_potential: Potential.Normal,
      kol_customized_tags_names: ['Education', 'Food'],
      engagement_count: 455,
      ...mentionedTopTenRestNonDisplayProps,
    },
    {
      type: 'image',
      post_id: 'C3cv1Tmvogj',
      ig_post_id: '3304726597952505891',
      is_disable_like: false,
      thumbnail_url: demoPostSrc9,
      title: '',
      content: '清爽的椰子冰淇淋，最佳甜點，帶你體驗熱帶風情。',
      published_at: '2024-02-17T12:48:51Z',
      mention_kols: [
        {
          uuid: mentionedBrandB.kol_uuid,
          name: mentionedBrandB.name,
          follower_count: 663667,
        },
      ],
      kol_uuid: '1ceff830-e1b8-492b-bf8a-1ccf94a76925',
      kol_name: '味蕾旅行家',
      kol_potential: Potential.Normal,
      kol_customized_tags_names: ['Food/Dessert', 'Food', 'Food/Beverage'],
      engagement_count: 444,
      ...mentionedTopTenRestNonDisplayProps,
    },
    {
      type: 'image',
      post_id: 'C3cv1Tmvogj',
      ig_post_id: '3304726597952505891',
      is_disable_like: false,
      thumbnail_url: demoPostSrc10,
      title: '',
      content: '蛋糕的層次，就像人生的起伏，這甜點值得細細品味。',
      mention_kols: [
        {
          uuid: mentionedBrandB.kol_uuid,
          name: mentionedBrandB.name,
          follower_count: 663667,
        },
      ],
      kol_uuid: '1ceff830-e1b8-492b-bf8a-1ccf94a76925',
      kol_name: '甜甜ㄉ每一天',
      kol_potential: Potential.Normal,
      kol_customized_tags_names: ['Food/Dessert', 'Food', 'Food/Beverage'],
      engagement_count: 226,
      published_at: '2024-02-17T12:48:51Z',
      ...mentionedTopTenRestNonDisplayProps,
    },
  ]

const demoCompetitiveMentionPostKolList: FetchCompetitiveMentionPostKolListResponse =
  {
    data: [
      {
        kol_name: '芽米ㄉ頻道',
        potential: Potential.Normal,
        customized_tags_names: [
          'Art and Entertainment',
          'Beauty and Fashion',
          'Daily Topics',
          'Daily Topics/Holidays',
        ],
        uuid: mockKolInfo1.id,
        platform: {
          platform: 3,
        },
        is_verified: false,
        is_radar_star: false,
        is_collection_kol: false,
        follower_count: 776110,
        follower_count_growth_rate: 0.013836383,
        avg_like_count: 8170,
        avg_comment_count: 52,
        avg_share_count: 0,
        avg_view_count: 0,
        avg_view_rate: 0,
        avg_engagement_count: 8222,
        avg_engagement_rate: 0.010593859478831291,
        post_count: 2,
        posts: [
          {
            type: 'image',
            is_disable_like: false,
            post_id: 'C4VpsdGSTTZ',
            ig_post_id: '',
            url: 'https://www.instagram.com/p/C4VpsdGSTTZ',
            thumbnail_url: demoPostSrc1,
            title: '',
            content:
              '早晨的陽光，一杯咖啡搭配著柔軟的甜點，為一天帶來美好的開始。',
            crawler_id: '299152231',
            platform_type: PlatformShortcode.Instagram,
            published_at: '2024-03-10T15:11:56Z',
            mention_kols: [
              {
                uuid: mentionedBrandC.kol_uuid,
                name: mentionedBrandC.name,
                follower_count: 954260,
              },
            ],
            is_advertorial: true,
            like_count: 8170,
            comment_count: 52,
            share_count: 0,
            view_count: 0,
            play_count: 0,
            is_post_collected: false,
          },
        ],
      },
      {
        kol_name: 'Bda 帶你吃吃喝喝',
        potential: Potential.Normal,
        customized_tags_names: ['Education', 'Food', 'Food/Beverage'],
        uuid: mockKolInfo2.id,
        platform: {
          platform: 3,
        },
        is_verified: false,
        is_radar_star: false,
        is_collection_kol: false,
        follower_count: 104620,
        follower_count_growth_rate: 0.05282225,
        avg_like_count: 2327,
        avg_comment_count: 37,
        avg_share_count: 0,
        avg_view_count: 0,
        avg_view_rate: 0,
        avg_engagement_count: 2364,
        avg_engagement_rate: 0.02259606122970581,
        post_count: 1,
        posts: [
          {
            type: 'image',
            post_id: 'C502yUVvZdO',
            is_disable_like: false,
            ig_post_id: '',
            url: 'https://www.instagram.com/p/C502yUVvZdO',
            thumbnail_url: demoPostSrc2,
            title: '',
            content:
              '走進街角小巷，發現了一家隱藏的甜點店，每一口都是驚奇的味覺冒險。',
            crawler_id: '11941118848',
            platform_type: PlatformShortcode.Instagram,
            published_at: '2024-04-16T14:34:10Z',
            mention_kols: [
              {
                uuid: mentionedBrandC.kol_uuid,
                name: mentionedBrandC.name,
                follower_count: 663667,
              },
            ],
            is_advertorial: false,
            like_count: 2327,
            comment_count: 37,
            share_count: 0,
            view_count: 0,
            play_count: 0,
            is_post_collected: false,
          },
        ],
      },
      {
        kol_name: '星星去走走',
        potential: Potential.Normal,
        customized_tags_names: [
          'Art and Entertainment',
          'Food',
          'Medical and Health',
          'Travel',
          'Daily Topics/Holidays',
          'Food/Beverage',
          'Travel/Taiwan Travel',
        ],
        uuid: mockKolInfo3.id,
        platform: {
          platform: 3,
        },
        is_verified: false,
        is_radar_star: false,
        is_collection_kol: false,
        follower_count: 85891,
        follower_count_growth_rate: 0.44449303,
        avg_like_count: 1596,
        avg_comment_count: 17,
        avg_share_count: 0,
        avg_view_count: 78907,
        avg_view_rate: 0.918687641620636,
        avg_engagement_count: 1613,
        avg_engagement_rate: 0.018779616802930832,
        post_count: 1,
        posts: [
          {
            type: 'short',
            post_id: 'C3pNnfYvei8',
            ig_post_id: '',
            url: 'https://www.instagram.com/p/C3pNnfYvei8',
            thumbnail_url: demoPostSrc3,
            is_disable_like: false,
            title: '',
            content:
              '在工作室裡，擁有一份美味的甜點，讓工作時光更加充實而美好。',
            crawler_id: '363118600',
            platform_type: PlatformShortcode.Instagram,
            published_at: '2024-02-22T09:00:22Z',
            mention_kols: [
              {
                uuid: mentionedBrandB.kol_uuid,
                name: mentionedBrandB.name,
                follower_count: 663667,
              },
            ],
            is_advertorial: false,
            like_count: 1596,
            comment_count: 17,
            share_count: 0,
            view_count: 78907,
            play_count: 78907,
            is_post_collected: false,
          },
        ],
      },
      {
        kol_name: '花兒 Flower🌸',
        potential: Potential.Normal,
        customized_tags_names: [
          'Pets/Cat',
          'Daily Topics',
          'Food',
          'Fortunetelling',
          'Daily Topics/Holidays',
        ],
        uuid: mockKolInfo4.id,
        platform: {
          platform: 3,
        },
        is_verified: false,
        is_radar_star: false,
        is_collection_kol: false,
        follower_count: 57021,
        follower_count_growth_rate: 0.007651799,
        avg_like_count: 440,
        avg_comment_count: 4,
        avg_share_count: 0,
        avg_view_count: 0,
        avg_view_rate: 0,
        avg_engagement_count: 444,
        avg_engagement_rate: 0.007786605041474104,
        post_count: 2,
        posts: [
          {
            type: 'image',
            post_id: 'C1_zyL0SnOu',
            is_disable_like: false,
            ig_post_id: '',
            url: 'https://www.instagram.com/p/C1_zyL0SnOu',
            thumbnail_url: demoPostSrc4,
            title: '',
            content:
              '靜靜地坐在窗邊，聽著雨聲，一口一口品味著甜點，這是最好的心靈慰藉。',
            crawler_id: '2221063875',
            platform_type: PlatformShortcode.Instagram,
            published_at: '2024-01-12T10:34:01Z',
            mention_kols: [
              {
                uuid: mentionedBrandB.kol_uuid,
                name: mentionedBrandB.name,
                follower_count: 663667,
              },
            ],
            is_advertorial: true,
            like_count: 440,
            comment_count: 4,
            share_count: 0,
            view_count: 0,
            play_count: 0,
            is_post_collected: false,
          },
        ],
      },
      {
        kol_name: '好好料理',
        potential: Potential.Normal,
        customized_tags_names: [
          'Food/Dessert',
          'Art and Entertainment',
          'Daily Topics',
          'Food',
          'Beauty and Fashion/Outfit',
        ],
        uuid: mockKolInfo5.id,
        platform: {
          platform: 3,
        },
        is_verified: true,
        is_radar_star: false,
        is_collection_kol: false,
        follower_count: 46758,
        follower_count_growth_rate: 0.033029184,
        avg_like_count: 3,
        avg_comment_count: 30,
        avg_share_count: 0,
        avg_view_count: 13005,
        avg_view_rate: 0.27812352776527405,
        avg_engagement_count: 33,
        avg_engagement_rate: 0.0007057615875964984,
        post_count: 4,
        posts: [
          {
            type: 'short',
            post_id: 'C3ksKKpSIFr',
            ig_post_id: '',
            url: 'https://www.instagram.com/p/C3ksKKpSIFr',
            is_disable_like: false,
            thumbnail_url: demoPostSrc5,
            title: '',
            content:
              '太適合當作禮物送出給朋友了，每一口都充滿著主廚的用心，感受到料理的精湛技藝。',
            crawler_id: '7569709581',
            platform_type: PlatformShortcode.Instagram,
            published_at: '2024-02-20T14:55:35Z',
            mention_kols: [
              {
                uuid: mentionedBrandA.kol_uuid,
                name: mentionedBrandA.name,
                follower_count: 663667,
              },
            ],
            is_advertorial: false,
            like_count: 3,
            comment_count: 45,
            share_count: 0,
            view_count: 12643,
            play_count: 12643,
            is_post_collected: false,
          },
        ],
      },
      {
        kol_name: '美食探險家',
        potential: Potential.Normal,
        customized_tags_names: ['Food', 'Food/Beverage'],
        uuid: mockKolInfo6.id,
        platform: {
          platform: 3,
        },
        is_verified: true,
        is_radar_star: false,
        is_collection_kol: false,
        follower_count: 43146,
        follower_count_growth_rate: 0.01932527,
        avg_like_count: 225,
        avg_comment_count: 1,
        avg_share_count: 0,
        avg_view_count: 0,
        avg_view_rate: 0,
        avg_engagement_count: 226,
        avg_engagement_rate: 0.005238029174506664,
        post_count: 1,
        posts: [
          {
            type: 'image',
            post_id: 'C0dQf0_vVnD',
            is_disable_like: false,
            ig_post_id: '',
            url: 'https://www.instagram.com/p/C0dQf0_vVnD',
            thumbnail_url: demoPostSrc6,
            title: '',
            content:
              '這間餐廳有著獨特的裝潢，氛圍極佳，主廚熱情，讓人有賓至如歸的感覺。',
            crawler_id: '1936745263',
            platform_type: PlatformShortcode.Instagram,
            published_at: '2023-12-05T04:00:06Z',
            mention_kols: [
              {
                uuid: mentionedBrandA.kol_uuid,
                name: mentionedBrandA.name,
                follower_count: 663667,
              },
            ],
            is_advertorial: true,
            like_count: 225,
            comment_count: 1,
            share_count: 0,
            view_count: 0,
            play_count: 0,
            is_post_collected: false,
          },
        ],
      },
      {
        kol_name: '雨天窩在窗邊',
        potential: Potential.Normal,
        customized_tags_names: [
          'Art and Entertainment/Podcast',
          'Food/Dessert',
          'Daily Topics',
          'Education',
          'Family',
          'Food',
          'Pets',
          'Beauty and Fashion/Outfit',
        ],
        uuid: mockKolInfo7.id,
        platform: {
          platform: 3,
        },
        is_verified: true,
        is_radar_star: false,
        is_collection_kol: false,
        follower_count: 40423,
        follower_count_growth_rate: 0.13382138,
        avg_like_count: 1677,
        avg_comment_count: 21,
        avg_share_count: 0,
        avg_view_count: 0,
        avg_view_rate: 0,
        avg_engagement_count: 1698,
        avg_engagement_rate: 0.042005788534879684,
        post_count: 1,
        posts: [
          {
            type: 'image',
            post_id: 'C1CZ07xPi4b',
            is_disable_like: false,
            ig_post_id: '',
            url: 'https://www.instagram.com/p/C1CZ07xPi4b',
            thumbnail_url: demoPostSrc7,
            title: '',
            content:
              '走出音樂會的場地，發現附近有一家美味的甜點店，為音樂之夜劃下完美的句點。',
            crawler_id: '647214484',
            platform_type: PlatformShortcode.Instagram,
            published_at: '2023-12-19T14:13:31Z',
            mention_kols: [
              {
                uuid: mentionedBrandB.kol_uuid,
                name: mentionedBrandB.name,
                follower_count: 663667,
              },
            ],
            is_advertorial: false,
            like_count: 1677,
            comment_count: 21,
            share_count: 0,
            view_count: 0,
            play_count: 0,
            is_post_collected: false,
          },
        ],
      },
      {
        kol_name: '甜點地圖',
        potential: Potential.Normal,
        customized_tags_names: ['Food', 'Fortunetelling', 'Law and Society'],
        uuid: mockKolInfo8.id,
        platform: {
          platform: 3,
        },
        is_verified: true,
        is_radar_star: false,
        is_collection_kol: false,
        follower_count: 24572,
        follower_count_growth_rate: 0.7572767,
        avg_like_count: 1109,
        avg_comment_count: 32,
        avg_share_count: 0,
        avg_view_count: 59240,
        avg_view_rate: 2.410874128341675,
        avg_engagement_count: 1141,
        avg_engagement_rate: 0.04643496498465538,
        post_count: 2,
        posts: [
          {
            type: 'short',
            is_disable_like: false,
            post_id: 'C47BwTMPHPd',
            ig_post_id: '',
            url: 'https://www.instagram.com/p/C47BwTMPHPd',
            thumbnail_url:
              'https://thumbnail.kolr.ai/instagram-post/C47BwTMPHPd',
            title: '',
            content:
              '用心做的蛋糕，帶來的不僅是味覺的享受，還有愛的溫暖，甜點帶來慰藉。',
            crawler_id: '30362457642',
            platform_type: PlatformShortcode.Instagram,
            published_at: '2024-03-25T03:36:49Z',
            mention_kols: [
              {
                uuid: mentionedBrandB.kol_uuid,
                name: mentionedBrandB.name,
                follower_count: 812372,
              },
            ],
            is_advertorial: false,
            like_count: 1109,
            comment_count: 32,
            share_count: 0,
            view_count: 59240,
            play_count: 59240,
            is_post_collected: false,
          },
        ],
      },
      {
        kol_name: '味蕾旅行家',
        potential: Potential.Normal,
        customized_tags_names: ['Daily Topics', 'Daily Topics/Holidays'],
        uuid: mockKolInfo9.id,
        platform: {
          platform: 3,
        },
        is_verified: false,
        is_radar_star: false,
        is_collection_kol: false,
        follower_count: 21522,
        follower_count_growth_rate: 0.009001407,
        avg_like_count: 0,
        avg_comment_count: 4,
        avg_share_count: 0,
        avg_view_count: 0,
        avg_view_rate: 0,
        avg_engagement_count: 4,
        avg_engagement_rate: 0.00018585633370094,
        post_count: 2,
        posts: [
          {
            type: 'image',
            post_id: 'C4petTkybWm',
            is_disable_like: false,
            ig_post_id: '',
            url: 'https://www.instagram.com/p/C4petTkybWm',
            thumbnail_url: demoPostSrc9,
            title: '',
            content: '清爽的椰子冰淇淋，最佳甜點，帶你體驗熱帶風情。',
            crawler_id: '4043546999',
            platform_type: PlatformShortcode.Instagram,
            published_at: '2024-03-18T08:00:44Z',
            mention_kols: [
              {
                uuid: mentionedBrandB.kol_uuid,
                name: mentionedBrandB.name,
                follower_count: 954260,
              },
            ],
            is_advertorial: false,
            like_count: 0,
            comment_count: 4,
            share_count: 0,
            view_count: 0,
            play_count: 0,
            is_post_collected: false,
          },
        ],
      },
      {
        kol_name: '甜甜ㄉ每一天',
        potential: null,
        customized_tags_names: ['Food', 'Food/Recipe'],
        uuid: mockKolInfo10.id,
        platform: {
          platform: 3,
        },
        is_verified: false,
        is_radar_star: false,
        is_collection_kol: false,
        follower_count: 21367,
        follower_count_growth_rate: 1.0237734,
        avg_like_count: 428,
        avg_comment_count: 27,
        avg_share_count: 0,
        avg_view_count: 16415,
        avg_view_rate: 0.768240749835968,
        avg_engagement_count: 455,
        avg_engagement_rate: 0.021294519305229187,
        post_count: 1,
        posts: [
          {
            type: 'short',
            post_id: 'C1VnoLjqZSL',
            is_disable_like: false,
            ig_post_id: '',
            url: 'https://www.instagram.com/p/C1VnoLjqZSL',
            thumbnail_url:
              'https://thumbnail.kolr.ai/instagram-post/C1VnoLjqZSL',
            title: '',
            content: '蛋糕的層次，就像人生的起伏，這甜點值得細細品味。',
            crawler_id: '18660580309',
            platform_type: PlatformShortcode.Instagram,
            published_at: '2023-12-27T01:20:20Z',
            mention_kols: [
              {
                uuid: mentionedBrandB.kol_uuid,
                name: mentionedBrandB.name,
                follower_count: 663667,
              },
            ],
            is_advertorial: true,
            like_count: 428,
            comment_count: 27,
            share_count: 0,
            view_count: 16415,
            play_count: 16415,
            is_post_collected: false,
          },
        ],
      },
    ],
    paging: {
      cursors: {
        after:
          'eyJzb3J0Ijp7ImZpZWxkIjoiZm9sbG93ZXJfY291bnRfc3RhdHMubWF4Iiwib3JkZXIiOiJkZXNjZW5kaW5nIn0sIm5leHQiOlsiMTM0NzkxIiwiNDcxMjYiLCIyNjI1MiIsIjE4ODE0IiwiMTA4NTMzIiwiMTI4MzAiLCI5ODAzNSIsIjczMDk3IiwiODk2NjYiLCIzMDI5OTI3Il19',
        before: null,
      },
    },
  }

const demoCompetitiveMentionAnalysisKolAnalyzeList: CompetitiveMentionAnalysisKolAnalyzeListResponse =
  {
    page: 1,
    per_page: 3,
    total: 3,
    total_page: 1,
    data: [
      {
        kol_info: {
          uuid: mentionedBrandB.kol_uuid,
          name: mentionedBrandB.name,
          starred: false,
        },
        mention: {
          kol_count: 9,
          post_count: 10,
          social_volume_count: 128804,
          like_count: 7193,
          comment_count: 280,
          share_count: 0,
          engagement_count: 7473,
          view_count: 121331,
        },
      },
      {
        kol_info: {
          uuid: mentionedBrandC.kol_uuid,
          name: mentionedBrandC.name,
          starred: false,
        },
        mention: {
          kol_count: 2,
          post_count: 2,
          social_volume_count: 8226,
          like_count: 8170,
          comment_count: 56,
          share_count: 0,
          engagement_count: 8226,
          view_count: 0,
        },
      },
      {
        kol_info: {
          uuid: mentionedBrandA.kol_uuid,
          name: mentionedBrandA.name,
          starred: false,
        },
        mention: {
          kol_count: 11,
          post_count: 11,
          social_volume_count: 76398,
          like_count: 1924,
          comment_count: 262,
          share_count: 0,
          engagement_count: 2186,
          view_count: 74212,
        },
      },
    ],
  }

const demoCompetitiveKeywordAnalysis: CompetitiveKeywordAnalysis = {
  id: -1,
  name: '產品 ABC 關鍵字分析',
  competitive_keyword_count: 3,
  updated_at: dayjs().format(),
  creator: {
    id: 0,
    name: 'Kolr',
  },
}

const keywordA: CompetitiveKeywordAnalysisKeyword = {
  id: 1,
  name: '關鍵字 Ａ',
  content: '食材 OR 主廚',
  starred_keyword: false,
  order_no: 1,
}

const keywordB: CompetitiveKeywordAnalysisKeyword = {
  id: 2,
  name: '關鍵字 B',
  content: '甜點',
  starred_keyword: false,
  order_no: 1,
}

const keywordC: CompetitiveKeywordAnalysisKeyword = {
  id: 3,
  name: '關鍵字 C',
  content: '咖啡 AND 早晨',
  starred_keyword: false,
  order_no: 1,
}

const demoCompetitiveKeywordAnalysisKeywordList: CompetitiveKeywordAnalysisKeywordList =
  {
    keywords: [keywordA, keywordB, keywordC],
  }

const demoCompetitiveKeywordKolFollowerChartData: FetchCompetitiveKeywordKolFollowerChartDataResponse =
  {
    follower_percentage_charts: [
      {
        id: keywordA.id,
        name: keywordA.name,
        starred_keyword: false,
        follower_level_percentages:
          demoCompetitiveMentionedKolFollowerChartData
            .follower_percentage_charts[0].follower_level_percentages,
      },
      {
        id: keywordB.id,
        name: keywordB.name,
        starred_keyword: false,
        follower_level_percentages:
          demoCompetitiveMentionedKolFollowerChartData
            .follower_percentage_charts[1].follower_level_percentages,
      },
      {
        id: keywordC.id,
        name: keywordC.name,
        starred_keyword: false,
        follower_level_percentages:
          demoCompetitiveMentionedKolFollowerChartData
            .follower_percentage_charts[2].follower_level_percentages,
      },
    ],
  }

const demoCompetitiveKeywordEffectivenessTrendList: CompetitiveKeywordEffectivenessTrendList =
  {
    keyword_infos: [
      {
        id: keywordA.id,
        name: keywordA.name,
        trend_data:
          demoCompetitiveMentionEffectivenessTrendChartData.kol_infos[0]
            .trend_data,
      },
      {
        id: keywordB.id,
        name: keywordB.name,
        trend_data:
          demoCompetitiveMentionEffectivenessTrendChartData.kol_infos[1]
            .trend_data,
      },
      {
        id: keywordC.id,
        name: keywordC.name,
        trend_data:
          demoCompetitiveMentionEffectivenessTrendChartData.kol_infos[2]
            .trend_data,
      },
    ],
  }

const demoCompetitiveKeywordKolCustomizedTagsNameChartData: FetchCompetitiveKeywordKolCustomizedTagsNameChartDataResponse =
  {
    mention_keyword_kol_customized_tags_name_charts:
      demoCompetitiveMentionKolPropertyChartData.mention_kol_property_charts,
  }

const demoCompetitiveKeywordSimilarKolList: FetchCompetitiveKeywordSimilarKolListResponse =
  demoCompetitiveMentionSimilarKolList

const demoCompetitiveKeywordTopTenPostList: CompetitiveKeywordTopTenPostList = [
  {
    ...demoCompetitiveMentionedTopTenPostList[0],
    mention_keywords: [keywordC],
  },
  {
    ...demoCompetitiveMentionedTopTenPostList[1],
    mention_keywords: [keywordB],
  },
  {
    ...demoCompetitiveMentionedTopTenPostList[2],
    mention_keywords: [keywordB],
  },
  {
    ...demoCompetitiveMentionedTopTenPostList[3],
    mention_keywords: [keywordB],
  },
  {
    ...demoCompetitiveMentionedTopTenPostList[4],
    mention_keywords: [keywordA],
  },
  {
    ...demoCompetitiveMentionedTopTenPostList[5],
    mention_keywords: [keywordA],
  },
  {
    ...demoCompetitiveMentionedTopTenPostList[6],
    mention_keywords: [keywordB],
  },
  {
    ...demoCompetitiveMentionedTopTenPostList[7],
    mention_keywords: [keywordB],
  },
  {
    ...demoCompetitiveMentionedTopTenPostList[8],
    mention_keywords: [keywordB],
  },
  {
    ...demoCompetitiveMentionedTopTenPostList[9],
    mention_keywords: [keywordB],
  },
]

const demoCompetitiveKeywordPostKolList: FetchCompetitiveKeywordPostKolListResponse =
  {
    data: [
      {
        ...omit(demoCompetitiveMentionPostKolList.data[0], 'post'),
        posts: [
          {
            ...omit(
              demoCompetitiveMentionPostKolList.data[0].posts[0],
              'mention_kols',
            ),
            mention_keywords: [keywordC],
          },
        ],
      },
      {
        ...omit(demoCompetitiveMentionPostKolList.data[1], 'post'),
        posts: [
          {
            ...omit(
              demoCompetitiveMentionPostKolList.data[1].posts[0],
              'mention_kols',
            ),
            mention_keywords: [keywordB],
          },
        ],
      },
      {
        ...omit(demoCompetitiveMentionPostKolList.data[2], 'post'),
        posts: [
          {
            ...omit(
              demoCompetitiveMentionPostKolList.data[2].posts[0],
              'mention_kols',
            ),
            mention_keywords: [keywordB],
          },
        ],
      },
      {
        ...omit(demoCompetitiveMentionPostKolList.data[3], 'post'),
        posts: [
          {
            ...omit(
              demoCompetitiveMentionPostKolList.data[3].posts[0],
              'mention_kols',
            ),
            mention_keywords: [keywordB],
          },
        ],
      },
      {
        ...omit(demoCompetitiveMentionPostKolList.data[4], 'post'),
        posts: [
          {
            ...omit(
              demoCompetitiveMentionPostKolList.data[4].posts[0],
              'mention_kols',
            ),
            mention_keywords: [keywordB],
          },
        ],
      },
      {
        ...omit(demoCompetitiveMentionPostKolList.data[5], 'post'),
        posts: [
          {
            ...omit(
              demoCompetitiveMentionPostKolList.data[5].posts[0],
              'mention_kols',
            ),
            mention_keywords: [keywordB],
          },
        ],
      },
      {
        ...omit(demoCompetitiveMentionPostKolList.data[6], 'post'),
        posts: [
          {
            ...omit(
              demoCompetitiveMentionPostKolList.data[6].posts[0],
              'mention_kols',
            ),
            mention_keywords: [keywordB],
          },
        ],
      },
      {
        ...omit(demoCompetitiveMentionPostKolList.data[7], 'post'),
        posts: [
          {
            ...omit(
              demoCompetitiveMentionPostKolList.data[7].posts[0],
              'mention_kols',
            ),
            mention_keywords: [keywordA],
          },
        ],
      },
      {
        ...omit(demoCompetitiveMentionPostKolList.data[8], 'post'),
        posts: [
          {
            ...omit(
              demoCompetitiveMentionPostKolList.data[8].posts[0],
              'mention_kols',
            ),
            mention_keywords: [keywordC],
          },
        ],
      },
      {
        ...omit(demoCompetitiveMentionPostKolList.data[9], 'post'),
        posts: [
          {
            ...omit(
              demoCompetitiveMentionPostKolList.data[9].posts[0],
              'mention_kols',
            ),
            mention_keywords: [keywordB],
          },
        ],
      },
    ],
    paging: demoCompetitiveMentionPostKolList.paging,
  }

const demoCompetitiveKeywordStatisticList: CompetitiveKeywordAnalysisStatisticList =
  {
    page: 1,
    per_page: 3,
    total: 3,
    total_page: 1,
    data: [
      {
        keyword_info: {
          ...keywordA,
          starred: keywordA.starred_keyword,
        },
        mention: demoCompetitiveMentionAnalysisKolAnalyzeList.data[0].mention,
      },
      {
        keyword_info: {
          ...keywordB,
          starred: keywordB.starred_keyword,
        },
        mention: demoCompetitiveMentionAnalysisKolAnalyzeList.data[1].mention,
      },
      {
        keyword_info: {
          ...keywordC,
          starred: keywordC.starred_keyword,
        },
        mention: demoCompetitiveMentionAnalysisKolAnalyzeList.data[2].mention,
      },
    ],
  }

const getDemoCompetitiveKeywordEfficientTrendDetail = (
  id: number,
  trendDataDate: string,
): CompetitiveKeywordEffectivenessTrendChartDetail => {
  const postInfoRecord = {
    [keywordA.id]: trendDetailDataDateList[0],
    [keywordB.id]: trendDetailDataDateList[1],
    [keywordC.id]: trendDetailDataDateList[2],
  }

  return {
    kol_uuid: '',
    kol_name: '',
    follower_count: 0,
    post_author_uuid: '',
    post_url: '',
    post_crawler_id: '',
    post_type: '',
    post_like_count: 0,
    post_comment_count: 0,
    post_view_count: 0,
    post_share_count: 0,
    post_social_volume_count: 1680,
    ...postInfoRecord[id]?.[trendDataDate],
  }
}

export {
  DEMO_COMPETITIVE_ANALYSIS_ID,
  demoCrawlerStatus,
  demoStaticHashtags,
  demoCompetitiveBrandAnalysisKolListEffectiveness,
  demoCompetitiveBrandAnalysisKolListCharts,
  demoCompetitiveBrandAnalysisKolListMetricCharts,
  demoCompetitiveBrandKolStatisticListCharts,
  demoCompetitiveBrandKolStatisticListTable,
  demoCompetitiveMentionAnalysis,
  demoCompetitiveMentionedKolList,
  demoCompetitiveMentionEffectivenessTrendChartData,
  demoCompetitiveMentionedKolFollowerChartData,
  demoCompetitiveMentionKolPropertyChartData,
  demoCompetitiveMentionSimilarKolList,
  demoCompetitiveMentionedTopTenPostList,
  demoCompetitiveMentionPostKolList,
  demoCompetitiveMentionAnalysisKolAnalyzeList,
  getDemoCompetitiveMentionedEfficientTrendDetail,
  demoCompetitiveKeywordAnalysis,
  demoCompetitiveKeywordAnalysisKeywordList,
  demoCompetitiveKeywordEffectivenessTrendList,
  demoCompetitiveKeywordKolFollowerChartData,
  demoCompetitiveKeywordKolCustomizedTagsNameChartData,
  demoCompetitiveKeywordSimilarKolList,
  demoCompetitiveKeywordTopTenPostList,
  demoCompetitiveKeywordPostKolList,
  demoCompetitiveKeywordStatisticList,
  getDemoCompetitiveKeywordEfficientTrendDetail,
}
