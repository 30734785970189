export enum Page {
  CampaignCase = '/campaign-case',
  CaseList = '/case-list',
  KolSelf = '/kol/self',
  KolAgencyId = '/kol/self/[kolId]',
  SettingsKol = '/settings/kol',
  KolSettings = '/kol-settings',
  CampaignList = '/campaign-list',
  CampaignListDetail = '/campaign-list/detail',
  CampaignListContract = '/campaign-list/contract',
  CampaignListCreateCampaign = '/campaign-list/create-campaign',
  CampaignPaid = '/campaign-paid/[workspaceId]',
  CompetitiveBrandAnalyses = '/competitive-brand-analyses',
  CompetitiveBrandComprehensiveAnalyses = `${CompetitiveBrandAnalyses}/[id]/comprehensive`,
  CompetitiveBrandPlatformsAnalyses = `${CompetitiveBrandAnalyses}/[id]/platforms`,
  CompetitiveMentionStatisticsAnalyses = `${CompetitiveBrandAnalyses}/[id]/mention-statistics`,
  CompetitiveMentionContentAnalyses = `${CompetitiveBrandAnalyses}/[id]/content`,
  CompetitiveKeywordAnalyses = '/competitive-keyword-analyses',
  CompetitiveKeywordStatisticsAnalyses = '/competitive-keyword-analyses/[id]/statistics',
  CompetitiveKeywordContentAnalyses = '/competitive-keyword-analyses/[id]/content',
  HashtagBillboard = '/hashtag-billboard',
  HashtagBillboardId = '/hashtag-billboard/[id]',
  HashtagWatchlist = '/hashtag-watchlist',
  HashtagWatchlistId = '/hashtag-watchlist/[id]',
  InfluencersRanking = '/influencers-ranking',
  KolManagement = '/kol-management',
  CollectionsKol = '/collections/kol',
  CollectionsPost = '/collections/post',
  CollectionsKolId = '/collections/kol/[id]',
  CollectionsPostId = '/collections/post/[id]',
  Report = '/report',
  ReportId = '/report/[workspaceId]/[reportId]',
  KolId = '/kol/[kolId]',
  KolCreate = '/kol/create',
  CreateWorkspace = '/create-workspace',
  Integrations = '/integrations',
  Search = '/search',
  AISearch = '/search/ai',
  Settings = '/settings',
  SettingsPassword = '/settings-password',
  WorkspaceHistory = '/workspace-history',
  WorkspaceMember = '/workspace-member',
  WorkspacePlan = '/workspace-plan',
  WorkspaceSetting = '/workspace-setting',
  WorkspaceValuation = '/workspace-valuation',
  SettingsAgent = '/settings/agent',
  Register = '/register',
  RegisterKOL = '/register/kol',
  RegisterAD = '/register/ad',
  Invitations = '/invitations',
  InviteWorkspace = '/invite-workspace',
  InstagramAuthRedirect = '/instagram-auth-redirect',
  Login = '/login',
  RedeemTrial = '/redeem-trial',
  RegisterSuccess = '/register-success',
  ResetPassword = '/reset-password',
  Verify = '/verify',
  VerifyAccount = '/verify-account',
  VerifyCreditCard = '/verify-credit-card',
  AcceptApplyCampaign = '/accept-apply-campaign',
  PublicKolDetail = '/influencer/[kolId]',
  BillboardTop100FacebookCommunityVolume = '/billboard/top100/facebook/community-volume',
  BillboardTop100FacebookGrowthRate = '/billboard/top100/facebook/growth-rate',
  BillboardTop100FacebookViewRate = '/billboard/top100/facebook/view-rate',
  BillboardTop100InstagramCommunityVolume = '/billboard/top100/instagram/community-volume',
  BillboardTop100InstagramGrowthRate = '/billboard/top100/instagram/growth-rate',
  BillboardTop100InstagramViewRate = '/billboard/top100/instagram/view-rate',
  BillboardTop100YouTubeCommunityVolume = '/billboard/top100/youtube/community-volume',
  BillboardTop100YouTubeGrowthRate = '/billboard/top100/youtube/growth-rate',
  BillboardTop100YouTubeViewRate = '/billboard/top100/youtube/view-rate',
  BillboardCustomizedTagList = '/billboard/[platform]/[country]/customized-tag',
  BillboardCustomizedTagDetail = '/billboard/[platform]/[country]/customized-tag/[tag]',
  BillboardFollowerLevelList = '/billboard/[platform]/[country]/follower-level',
  BillboardFollowerLevelDetail = '/billboard/[platform]/[country]/follower-level/[level]',
  BillboardStatisticList = `/billboard/[platform]/[country]/statistics`,
  BillboardStatisticDetail = '/billboard/[platform]/[country]/statistics/[statistic]',
  SubscriptionCheckout = '/subscription-checkout',
  SubscriptionCheckoutVerify = '/subscription-checkout/verify',
  PredictiveReport = '/predictive-report',
  PredictiveReportIdList = '/predictive-report/[id]/list',
  PredictiveReportIdReport = '/predictive-report/[id]/report',
  Default = '/',
}

export enum PageAuthentication {
  Authenticated = 'authenticated',
  Unauthenticated = 'unauthenticated',
  Ignore = 'ignore',
}

export enum UserType {
  NormalAd = 'ad',
  NormalKol = 'kol',
  Agency = 'kolAgency',
  // ref: https://gitlab.corp.ikala.tv/kolradar/kol-service/-/merge_requests/6920#note_373106
  Untyped = '-',
}

export const AD_PAGES: Page[] = [
  Page.WorkspaceValuation,
  Page.WorkspaceSetting,
  Page.WorkspacePlan,
  Page.WorkspaceMember,
  Page.WorkspaceHistory,
  Page.Settings,
  Page.SettingsPassword,
  Page.Search,
  Page.AISearch,
  Page.Integrations,
  Page.InviteWorkspace,
  Page.CreateWorkspace,
  Page.KolId,
  Page.ReportId,
  Page.Report,
  Page.CollectionsKol,
  Page.CollectionsKol,
  Page.CollectionsPost,
  Page.CollectionsKolId,
  Page.CollectionsPostId,
  Page.HashtagWatchlistId,
  Page.HashtagWatchlist,
  Page.HashtagBillboardId,
  Page.HashtagBillboard,
  Page.InfluencersRanking,
  Page.CampaignPaid,
  Page.CampaignListCreateCampaign,
  Page.CampaignListContract,
  Page.CampaignList,
  Page.CampaignListDetail,
  Page.SubscriptionCheckout,
  Page.SubscriptionCheckoutVerify,
  Page.VerifyCreditCard,
  Page.CompetitiveBrandAnalyses,
  Page.CompetitiveBrandComprehensiveAnalyses,
  Page.CompetitiveBrandPlatformsAnalyses,
  Page.CompetitiveMentionStatisticsAnalyses,
  Page.CompetitiveMentionContentAnalyses,
  Page.CompetitiveKeywordAnalyses,
  Page.CompetitiveKeywordStatisticsAnalyses,
  Page.CompetitiveKeywordContentAnalyses,
  Page.KolManagement,
  Page.KolCreate,
  Page.PredictiveReport,
  Page.PredictiveReportIdList,
  Page.PredictiveReportIdReport,
]

export const KOL_PAGES: Page[] = [
  Page.CampaignCase,
  Page.CaseList,
  Page.KolSelf,
  Page.SettingsKol,
  Page.KolSettings,
  Page.VerifyAccount,
  Page.Invitations,
]

export const AGENCY_PAGES: Page[] = [
  Page.CampaignCase,
  Page.CaseList,
  Page.KolAgencyId,
  Page.SettingsAgent,
  Page.SettingsKol,
  Page.KolSettings,
  Page.VerifyAccount,
  Page.Invitations,
]

export const WITHOUT_LOGIN_PAGES: Page[] = [
  Page.Register,
  Page.RegisterKOL,
  Page.RegisterAD,
  Page.Login,
  Page.RegisterSuccess,
  Page.ResetPassword,
]

export const BILLBOARD_PAGES: Page[] = [
  Page.BillboardTop100FacebookCommunityVolume,
  Page.BillboardTop100FacebookGrowthRate,
  Page.BillboardTop100FacebookViewRate,
  Page.BillboardTop100InstagramCommunityVolume,
  Page.BillboardTop100InstagramGrowthRate,
  Page.BillboardTop100InstagramViewRate,
  Page.BillboardTop100YouTubeCommunityVolume,
  Page.BillboardTop100YouTubeGrowthRate,
  Page.BillboardTop100YouTubeViewRate,
]

export const BILLBOARD_MULTI_DIMENSION_PAGES: Page[] = [
  Page.BillboardCustomizedTagList,
  Page.BillboardCustomizedTagDetail,
  Page.BillboardFollowerLevelList,
  Page.BillboardFollowerLevelDetail,
  Page.BillboardStatisticList,
  Page.BillboardStatisticDetail,
]

export const NO_RWD_PAGE_LIST: Page[] = [Page.PublicKolDetail]
export const RWD_ENABLED_PAGE_LIST: Page[] = [
  ...WITHOUT_LOGIN_PAGES,
  ...BILLBOARD_PAGES,
  ...BILLBOARD_MULTI_DIMENSION_PAGES,
  Page.CreateWorkspace,
  Page.InviteWorkspace,
]

export const PUBLIC_PAGES: Page[] = [
  ...BILLBOARD_PAGES,
  ...BILLBOARD_MULTI_DIMENSION_PAGES,
  Page.AcceptApplyCampaign,
  Page.Verify,
  Page.PublicKolDetail,
  Page.RedeemTrial,
  Page.InstagramAuthRedirect,
]

export const OPEN_GRAPH_PAGES: Page[] = [
  Page.PublicKolDetail,
  Page.KolId,
  ...BILLBOARD_PAGES,
  ...BILLBOARD_MULTI_DIMENSION_PAGES,
]
