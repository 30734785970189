import { uniqBy } from 'lodash-es'
import { Link } from '@/types/schema/kol-schema'
import { PlatformType } from '@/types/schema/report-schema'
import { PlatformShortcode } from '@/utils/convert-platform'
import { getPlatformShortName } from '@/utils/get-platform-name'

const platformSortScore = {
  all: -1,
  ig: 0,
  fb: 1,
  yt: 2,
  tiktok: 3,
  blog: 4,
  twitter: 5,
  twitch: 6,
  weibo: 7,
  plurk: 8,
  others: 9,
}

const platformTypeSortScore = {
  [PlatformType.All]: -1,
  [PlatformType.Instagram]: 0,
  [PlatformType.Facebook]: 1,
  [PlatformType.YouTube]: 2,
  [PlatformType.TikTok]: 3,
  // blog: 4,
  [PlatformType.Twitter]: 5,
  // twitch: 6,
  // weibo: 7,
  // plurk: 8,
  [PlatformType.Other]: 9,
}

const platformShortcodeSortScore = {
  [PlatformShortcode.All]: -1,
  [PlatformShortcode.Instagram]: 0,
  [PlatformShortcode.Facebook]: 1,
  [PlatformShortcode.YouTube]: 2,
  [PlatformShortcode.TikTok]: 3,
  [PlatformShortcode.Twitter]: 5,
  [PlatformShortcode.Other]: 9,
}

export function getMainPlatformsByLinks(links: Link[] = []): Link[] {
  return uniqBy(links, 'type').sort(
    ({ type: platform1 = 'others' }, { type: platform2 = 'others' }) => {
      const platform1Score = platformSortScore[platform1]
      const platform2Score = platformSortScore[platform2]
      return (
        (platform1Score === undefined ? 999 : platform1Score) -
        (platform2Score === undefined ? 999 : platform2Score)
      )
    },
  )
}

export function getMainPlatformsByPlatformType(
  platforms: string[] = [],
): string[] {
  return platforms.sort((platform1 = 'others', platform2 = 'others') => {
    const platform1Score =
      platformSortScore[getPlatformShortName(parseInt(platform1))]
    const platform2Score =
      platformSortScore[getPlatformShortName(parseInt(platform2))]
    return (
      (platform1Score === undefined ? 999 : platform1Score) -
      (platform2Score === undefined ? 999 : platform2Score)
    )
  })
}

type SortCallback<T> = (prevData: T, nextData: T) => number

function createPlatformSortCallback<T>(scoreMap: Record<string, number>) {
  return (key: keyof T): SortCallback<T> =>
    (prevData: T, nextData: T): number => {
      const prevDataScore = scoreMap[prevData[key] as string]
      const nextDataScore = scoreMap[nextData[key] as string]
      return (prevDataScore ?? 999) - (nextDataScore ?? 999)
    }
}

export const createPlatformScoreSortCallback = <T>(
  key: keyof T,
): SortCallback<T> => createPlatformSortCallback<T>(platformSortScore)(key)

export const createPlatformTypeScoreSortCallback = <T>(
  key: keyof T,
): SortCallback<T> => createPlatformSortCallback<T>(platformTypeSortScore)(key)

export const createPlatformShortcodeScoreSortCallback = <T>(
  key: keyof T,
): SortCallback<T> =>
  createPlatformSortCallback<T>(platformShortcodeSortScore)(key)

export function sortByPlatformScore<T>(data: T[] = [], key: keyof T): T[] {
  return uniqBy(data, key).sort(createPlatformScoreSortCallback<T>(key))
}

export function sortByPlatformTypeScore<T>(data: T[] = [], key: keyof T): T[] {
  return uniqBy(data, key).sort(createPlatformTypeScoreSortCallback<T>(key))
}

export function sortByPlatformShortcodeScore<T>(
  data: T[] = [],
  key: keyof T,
): T[] {
  return uniqBy(data, key).sort(
    createPlatformShortcodeScoreSortCallback<T>(key),
  )
}
