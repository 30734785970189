import { z } from 'zod'
import { ownerSchema } from '@/types/schema/api/owner-schema'
import {
  competitiveAnalysisPostKolSchema,
  followerLevelPercentageSchema,
} from '@/types/schema/competitive-analyses/index'
import { PlatformShortcode } from '@/utils/convert-platform'

export const competitiveMentionAnalysisSchema = z.object({
  id: z.number(),
  name: z.string(),
  competitive_kol_count: z.number(),
  updated_at: z.string().datetime(),
  creator: ownerSchema,
  latest_updated_at: z.string().datetime().nullish(),
})
export type CompetitiveMentionAnalysis = z.infer<
  typeof competitiveMentionAnalysisSchema
>

export const competitiveMentionKolFollowerChartDataSchema = z.object({
  uuid: z.string(),
  name: z.string(),
  starred_kol: z.boolean(),
  follower_level_percentages: followerLevelPercentageSchema.array(),
})
export type CompetitiveMentionKolFollowerChartData = z.infer<
  typeof competitiveMentionKolFollowerChartDataSchema
>

export const competitiveMentionKolPropertyChartDataSchema = z.object({
  customized_tags_name: z.string(),
  competitive_kol_percentage: z.number().nullable(),
  starred_kol_percentage: z.number().nullable(),
})

export const competitiveMentionKeywordKolCustomizedTagsNameChartDataSchema =
  z.object({
    customized_tags_name: z.string(),
    competitive_kol_percentage: z.number().nullable(),
    starred_kol_percentage: z.number().nullable(),
  })

export type CompetitiveMentionKolPropertyChartData = z.infer<
  typeof competitiveMentionKolPropertyChartDataSchema
>

export type CompetitiveMentionKeywordKolCustomizedTagsNameChartData = z.infer<
  typeof competitiveMentionKeywordKolCustomizedTagsNameChartDataSchema
>
export const competitiveMentionAnalysisKolAnalyzeSchema = z.object({
  kol_info: z.object({
    uuid: z.string(),
    name: z.string(),
    starred: z.boolean(),
  }),
  mention: z.object({
    kol_count: z.number(),
    post_count: z.number(),
    social_volume_count: z.number(),
    like_count: z.number(),
    comment_count: z.number(),
    share_count: z.number(),
    engagement_count: z.number(),
    view_count: z.number(),
  }),
})
export type CompetitiveMentionAnalysisKolAnalyze = z.infer<
  typeof competitiveMentionAnalysisKolAnalyzeSchema
>

export const competitiveMentionSimilarKolSchema = z.object({
  kol_uuid: z.string(),
  name: z.string(),
  follower_count: z.number().nullable(),
  customized_tags_names: z.string().array(),
})

export const competitiveMentionPostSchema = z.object({
  type: z.string(),
  post_id: z.string(),
  ig_post_id: z.string(),
  url: z.string(),
  thumbnail_url: z.string(),
  title: z.string(),
  content: z.string(),
  crawler_id: z.string(),
  platform_type: z.nativeEnum(PlatformShortcode),
  published_at: z.string().datetime().nullable(),
  mention_kols: z
    .object({
      uuid: z.string(),
      name: z.string(),
      follower_count: z.number().nullable(),
    })
    .array(),
  is_advertorial: z.boolean(),
  like_count: z.number().nullable(),
  comment_count: z.number().nullable(),
  share_count: z.number().nullable(),
  view_count: z.number().nullable(),
  play_count: z.number().nullable(),
  is_post_collected: z.boolean(),
  is_disable_like: z.boolean(),
})
export type CompetitiveMentionPost = z.infer<
  typeof competitiveMentionPostSchema
>

export const competitiveMentionPostKolSchema =
  competitiveAnalysisPostKolSchema.extend({
    posts: competitiveMentionPostSchema.array(),
  })
export type CompetitiveMentionPostKol = z.infer<
  typeof competitiveMentionPostKolSchema
>
export type CompetitiveMentionPostKolSortableProps = Pick<
  CompetitiveMentionPostKol,
  | 'follower_count'
  | 'avg_engagement_rate'
  | 'avg_view_rate'
  | 'avg_like_count'
  | 'avg_comment_count'
  | 'avg_share_count'
  | 'avg_engagement_count'
  | 'avg_view_count'
>

const competitiveMentionEffectivenessTrendChartTrendDataSchema = z.object({
  key: z.string(),
  from: z.string().datetime().nullable(),
  to: z.string().datetime().nullable(),
  social_volume_count: z.number(),
  post_count: z.number(),
  kol_count: z.number(),
  avg_follower_count: z.number().transform((value) => Math.ceil(value)),
  like_count: z.number(),
  comment_count: z.number(),
  view_count: z.number(),
  share_count: z.number(),
  engagement_count: z.number(),
})
export type CompetitiveMentionEffectivenessTrendChartTrendData = z.infer<
  typeof competitiveMentionEffectivenessTrendChartTrendDataSchema
>

export const competitiveMentionEffectivenessTrendChartDataSchema = z.object({
  uuid: z.string(),
  name: z.string(),
  trend_data: competitiveMentionEffectivenessTrendChartTrendDataSchema.array(),
})

export const competitiveMentionEffectivenessTrendChartDetailSchema = z.object({
  kol_uuid: z.string(),
  kol_name: z.string(),
  follower_count: z.number(),
  post_id: z.string(),
  post_author_uuid: z.string(),
  post_author_name: z.string(),
  post_title: z.string(),
  post_engagement_count: z.number(),
  post_social_volume_count: z.number(),
  post_thumbnail_url: z.string(),
  post_url: z.string(),
  post_crawler_id: z.string(),
  post_type: z.string(),
  post_like_count: z.number(),
  post_comment_count: z.number(),
  post_view_count: z.number(),
  post_share_count: z.number(),
})
export type CompetitiveMentionEffectivenessTrendChartDetail = z.infer<
  typeof competitiveMentionEffectivenessTrendChartDetailSchema
>

export const createCompetitiveMentionAnalysisResponseSchema = z.object({
  id: z.number(),
})
export type CreateCompetitiveMentionAnalysisResponse = z.infer<
  typeof createCompetitiveMentionAnalysisResponseSchema
>
