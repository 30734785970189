import { ConfigProvider, ConfigProvider as AntdConfigProvider } from 'antd'
import enUS from 'antd/locale/en_US'
import ja from 'antd/locale/ja_JP'
import th from 'antd/locale/th_TH'
import zhTW from 'antd/locale/zh_TW'
import dayjs from 'dayjs'
import dayOfYear from 'dayjs/plugin/dayOfYear'
import isBetween from 'dayjs/plugin/isBetween'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekday from 'dayjs/plugin/weekday'
import noop from 'lodash-es/noop'
import omit from 'lodash-es/omit'
import { NextPage } from 'next'
import { NextAdapter } from 'next-query-params'
import Head from 'next/head'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { parse, ParsedQuery } from 'query-string'
import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { IntlProvider } from 'react-intl'
import { useFavicon, useMount, useNetworkState } from 'react-use'
import {
  createGlobalStyle,
  css,
  FlattenSimpleInterpolation,
  ThemeProvider,
} from 'styled-components'
import { QueryParamOptions, QueryParamProvider } from 'use-query-params'
import { FeatureFlag, useFetchUserStatusQuery } from '@/api/user-api'
import { ipService } from '@/api/utils/ip-service'
import { UserLayout } from '@/components/app/user-layout/user-layout'
import InContext from '@/components/crowdin/in-context'
import { GlobalStyles } from '@/components/global-styles'
import LoadingPage from '@/components/loading-page'
import RootErrorBoundary from '@/components/root-error-boundary'
import RecaptchaScript from '@/components/script/recaptcha-script'
import FacebookDomainVerificationMeta from '@/components/tracking/facebook-domain-verification-meta'
import FbPixelComponent from '@/components/tracking/fb-pixel-component'
import GoogleAnalyticsScript from '@/components/tracking/google-analytics-script'
import GoogleTagScript from '@/components/tracking/google-tag-script'
import TaboolaPixelScript from '@/components/tracking/taboola-pixel-script'
import SiteWarning from '@/components/warning'
import useSiteWarning from '@/components/warning/use-site-warning'
import useAmplitudeInit from '@/hooks/use-amplitude-init'
import { useAuth } from '@/hooks/use-auth'
import {
  NO_RWD_PAGE_LIST,
  OPEN_GRAPH_PAGES,
  Page,
  RWD_ENABLED_PAGE_LIST,
} from '@/hooks/use-authorization/constants'
import getPage from '@/hooks/use-authorization/get-page'
import useAuthorization from '@/hooks/use-authorization/use-authorization'
import useFeatureFlags from '@/hooks/use-feature-flags'
import { useI18n, useMessagesState } from '@/hooks/use-i18n'
import useMergedTheme from '@/hooks/use-merged-theme'
import { useNotifications } from '@/hooks/use-notifications'
import { useScript } from '@/hooks/use-script'
import useSearchIdManagerInit from '@/hooks/use-search-id-manager-init'
import useSentryInit from '@/hooks/use-sentry-init'
import useUserMetadataInit from '@/hooks/use-user-metadata-init'
import { SupportedLanguages } from '@/i18n/config'
import { PageProps } from '@/pages/_app'
import favicon from '@/public/assets/kolr-logo/kolr-logo-icon.png'
import unixNanoseconds from '@/utils/dayjs-plugin/unix-nanoseconds'
import useFontFamily from '@/utils/hooks/use-font-family'
import { getIntlLocal } from '@/utils/i18n/get-intl-local'
import { loadIntlPolyfillIfNeeded } from '@/utils/i18n/load-intl-polyfill-if-needed'
import { goToPage } from '@/utils/routes/go-to-page'

dayjs.extend(isBetween)
dayjs.extend(dayOfYear)
dayjs.extend(weekOfYear)
dayjs.extend(unixNanoseconds)
dayjs.extend(weekday)

type NextLayoutPage<P = NonNullable<unknown>> = NextPage<P> & {
  getLayout?: (page: ReactNode, pageProps?: PageProps) => ReactNode
}

interface AppLayoutProps {
  pageProps: PageProps
  Component: NextLayoutPage
}

const HelpScoutStyle = createGlobalStyle<{ enableHelpScout?: boolean }>`
  ${({ enableHelpScout }): FlattenSimpleInterpolation =>
    enableHelpScout
      ? css`
          .BeaconFabButtonFrame {
            right: 20px !important;
            bottom: 20px !important;
            /** NOTE: z-index of table fixed column is 2, so this button should be on the same layer */
            z-index: 2 !important;
            overflow: hidden;
          }
          .BeaconFabButtonFrame > span {
            display: none;
          }
        `
      : css`
          #beacon-container {
            display: none !important;
          }
        `}
`

const AppLayout: FunctionComponent<AppLayoutProps> = ({
  Component,
  pageProps,
}) => {
  const [isClient, setIsClient] = useState(false)
  const { isAuthorizing, isAuthorized } = useAuthorization({
    pathname: pageProps.pathname,
  })
  const version = process.env.NEXT_PUBLIC_VERSION ?? 'local-version'
  const { locale, language } = useI18n()
  const messagesState = useMessagesState(locale)
  const router = useRouter()

  const { withFeature } = useFeatureFlags()
  const { userInfo, isAd } = useAuth()
  const getNotifications = useNotifications()

  const [isSkipWarning, setIsSkipWarning] = useState(false)
  const warningType = useSiteWarning()
  const needWarning = warningType !== null && !isSkipWarning
  const fontFamily = useFontFamily()
  const theme = useMergedTheme()
  const { data: userStatus } = useFetchUserStatusQuery()
  useFavicon(
    userStatus?.currentWorkspace.enterpriseIdentity?.appLogoUrl ?? favicon,
  )

  const NO_DECODE_URI_PAGE_LIST: string[] = [Page.Login, Page.Default]
  const queryParamOptions: QueryParamOptions = {
    searchStringToObject: (query): ParsedQuery<string> => {
      if (NO_DECODE_URI_PAGE_LIST.includes(router.pathname)) {
        return parse(query)
      }
      const queryString = decodeURIComponent(query)
      return parse(queryString)
    },
  }

  /**
   * WORKAROUND: In Chrome based browsers, this will trigger DOM change and re-render all subcomponents.
   */
  const disabledRWD = useMemo((): boolean => {
    const page = getPage(router.pathname) ?? Page.Default

    if (RWD_ENABLED_PAGE_LIST.includes(page)) {
      return false
    }

    if (isAd || [Page.KolSelf, Page.KolAgencyId].includes(page)) {
      return !needWarning
    }

    return NO_RWD_PAGE_LIST.includes(page)
  }, [isAd, needWarning, router.pathname])
  const loading = isAuthorizing || messagesState.loading
  const getLayout = (page: ReactNode, pageProps?: PageProps): ReactNode => {
    return (
      <UserLayout>
        {Component.getLayout ? Component.getLayout(page, pageProps) : page}
      </UserLayout>
    )
  }

  useSearchIdManagerInit()
  useAmplitudeInit()
  useSentryInit()
  useUserMetadataInit()

  /**
   * @todo extract to custom hook
   */
  const [googleApiScriptLoaded] = useScript(
    'https://apis.google.com/js/api:client.js',
  )

  useEffect(() => {
    if (googleApiScriptLoaded) {
      window.gapi?.load('auth2', () => {
        window.gapi.auth2.init({
          client_id: `${process.env.NEXT_PUBLIC_GOOGLE_APP_ID}.apps.googleusercontent.com`,
          cookiepolicy: 'single_host_origin',
        })
      })
    }
  }, [googleApiScriptLoaded])

  /**
   * @deprecated for sso quit user, user without type and incompleteRegistration
   * @todo move to login or useAuthorization
   */
  useEffect(() => {
    if (
      userInfo &&
      userInfo.type === undefined &&
      userInfo.incompleteRegistration === undefined &&
      router.pathname !== Page.Register
    ) {
      goToPage(Page.Default).then(noop)
    }
  }, [userInfo, router.pathname])

  /**
   * @todo extract to custom hook
   */
  useEffect(() => {
    if (withFeature(FeatureFlag.DualWheel)) {
      getNotifications()
    }
  }, [getNotifications, withFeature])

  useMount(async () => {
    setIsClient(true)
    await loadIntlPolyfillIfNeeded(getIntlLocal(locale))
  })

  useEffect(() => {
    ConfigProvider.config({
      holderRender: (children) => (
        <AntdConfigProvider
          autoInsertSpaceInButton={false}
          locale={{ enUS, zhTW, ja, th }[language.i18nCode]}
          theme={theme}
        >
          <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </AntdConfigProvider>
      ),
    })
  }, [language.i18nCode, theme])

  const network = useNetworkState()

  useEffect(() => {
    // network.previous === undefined 代表初次載入，不需要重新取得 ip
    if (network.previous === false && network.online) {
      ipService.refetchIp()
    }
  }, [network])

  // server side rendering 時，不去引 script 加快速度，並且把不支援 SSR 的 Provider 移除
  if (!isClient) {
    return (
      <QueryParamProvider adapter={NextAdapter} options={queryParamOptions}>
        <ThemeProvider theme={theme}>
          <GlobalStyles fontFamily={fontFamily} />
          <IntlProvider
            locale={getIntlLocal(locale)}
            messages={pageProps.messages}
          >
            <AntdConfigProvider
              autoInsertSpaceInButton={false}
              locale={{ enUS, zhTW, ja, th }[language.i18nCode]}
              theme={{
                ...theme,
                token: {
                  ...theme.token,
                  fontFamily,
                },
              }}
            >
              <Head>
                <FacebookDomainVerificationMeta />
                {disabledRWD && (
                  <meta content='viewport-fit=cover' name='viewport' />
                )}
              </Head>
              {isAuthorizing ? (
                <LoadingPage />
              ) : (
                OPEN_GRAPH_PAGES.includes(
                  getPage(router.pathname) ?? Page.Default,
                ) &&
                getLayout(
                  <Component {...omit(pageProps, 'pathname', 'messages')} />,
                  pageProps,
                )
              )}
            </AntdConfigProvider>
          </IntlProvider>
        </ThemeProvider>
      </QueryParamProvider>
    )
  }

  return (
    <QueryParamProvider adapter={NextAdapter} options={queryParamOptions}>
      <InContext />
      <FbPixelComponent />
      <GoogleTagScript />
      <GoogleAnalyticsScript />
      <TaboolaPixelScript />
      <RecaptchaScript />
      <ThemeProvider theme={theme}>
        <GlobalStyles fontFamily={fontFamily} />
        <IntlProvider
          locale={getIntlLocal(locale)}
          messages={messagesState.value}
        >
          <AntdConfigProvider
            autoInsertSpaceInButton={false}
            locale={{ enUS, zhTW, ja, th }[language.i18nCode]}
            theme={{
              ...theme,
              token: {
                ...theme.token,
                fontFamily,
              },
            }}
          >
            <RootErrorBoundary>
              <Head>
                <FacebookDomainVerificationMeta />
                {disabledRWD && (
                  <meta content='viewport-fit=cover' name='viewport' />
                )}
                <script
                  async
                  defer
                  src='https://connect.facebook.net/en_US/sdk.js'
                />
                <script
                  dangerouslySetInnerHTML={{
                    __html: `(function(g,d,o){
                  g._ltq=g._ltq||[];g._lt=g._lt||function(){g._ltq.push(arguments)};
                  var h='https://d.line-scdn.net';
                  var s=d.createElement('script');s.async=1;
                  s.src=o||h+'/n/line_tag/public/release/v1/lt.js';
                  var t=d.getElementsByTagName('script')[0];t.parentNode.insertBefore(s,t);
                })(window, document);
                  _lt('init', {
                  customerType: 'lap',
                  tagId: '23526b4a-11a7-4a22-8559-5c14c996e31a'
                });
                  _lt('send', 'pv', ['23526b4a-11a7-4a22-8559-5c14c996e31a']);`,
                  }}
                />
                <noscript>
                  <Image
                    alt='line'
                    height='0'
                    src='https://tr.line.me/tag.gif?c_t=lap&t_id=749c137a-f375-43fd-b75f-70b6a5caa46d&e=pv&noscript=1'
                    width='0'
                  />
                  <iframe
                    height='0'
                    src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GTM_ID}`}
                    style={{ display: 'none', visibility: 'hidden' }}
                    width='0'
                  ></iframe>
                </noscript>
                <script
                  dangerouslySetInnerHTML={{
                    __html: `!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
                  },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='//static.ads-twitter.com/uwt.js',
                  a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
                  twq('init','o76td');
                  twq('track','PageView');
                  `,
                  }}
                />
                <script
                  dangerouslySetInnerHTML={{
                    __html: `
                  var version='${version}';
                `,
                  }}
                />
                <script
                  dangerouslySetInnerHTML={{
                    __html: `
                  window.fbAsyncInit = function() {
                    FB.init({
                      appId      : ${process.env.NEXT_PUBLIC_FACEBOOK_APP_ID},
                      cookie     : true,
                      xfbml      : true,
                      version    : 'v21.0'
                    });
                  };
                `,
                  }}
                />
                <script
                  dangerouslySetInnerHTML={{
                    __html: `!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});`,
                  }}
                />
                <script
                  dangerouslySetInnerHTML={{
                    __html: `window.Beacon('init', '6607bd25-15e1-4ef7-90c7-be7d454ff73d')`,
                  }}
                />
              </Head>
              {((): ReactNode => {
                if (needWarning) {
                  return (
                    <SiteWarning
                      type={warningType}
                      onSkip={(): void => {
                        setIsSkipWarning(true)
                      }}
                    />
                  )
                }

                if (loading || !isAuthorized) {
                  return <LoadingPage />
                }

                return getLayout(
                  <Component {...omit(pageProps, 'pathname', 'messages')} />,
                  pageProps,
                )
              })()}
              <HelpScoutStyle
                enableHelpScout={language.i18nCode === SupportedLanguages.zhTW}
              />
            </RootErrorBoundary>
          </AntdConfigProvider>
        </IntlProvider>
      </ThemeProvider>
    </QueryParamProvider>
  )
}

export default AppLayout
