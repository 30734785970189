import env from '@/utils/env'

class IpService {
  private async fetchIpFromApi(): Promise<string> {
    const response = await fetch(`${env.APP_URL}/ip`)
    if (!response.ok) {
      throw new Error('Failed to fetch IP address')
    }

    return await response.text()
  }

  private fetchPromise: Promise<string>

  async refetchIp(): Promise<void> {
    this.fetchPromise = this.fetchIpFromApi()
  }

  async getIp(): Promise<string> {
    if (!this.fetchPromise) {
      this.refetchIp()
    }

    return this.fetchPromise
  }
}

export const ipService = new IpService()
