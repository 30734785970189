import * as Sentry from '@sentry/react'
import axios, { AxiosInstance } from 'axios'
import humps from 'humps'
import { assign } from 'lodash-es'
import isEmpty from 'lodash-es/isEmpty'
import {
  BOGUS_TOKEN_NAME,
  getBogusToken,
} from '@/api/utils/bogus-token-injector'
import { ApiConfig } from '@/types/api-config'

/**
 * @deprecated
 * use RTK Query
 */
export class ApiCaller {
  private readonly axiosInstance: AxiosInstance

  constructor(private readonly config: ApiConfig) {
    const { baseURL, headers } = config
    this.axiosInstance = axios.create({
      baseURL,
      headers,
    })
    this.setUpInterceptors()
  }

  public get axios(): AxiosInstance {
    return this.axiosInstance
  }

  private setUpInterceptors(): void {
    const { tokenProvider } = this.config
    // interceptors 先入後出
    this.axiosInstance.interceptors.request.use(async (config) => {
      try {
        const token = await tokenProvider(config.url as string)
        if (token && config.headers) {
          config.headers.Authorization = token
        }

        const bogusToken = await getBogusToken(
          config.url!,
          config.params,
          config.data,
        )

        if (bogusToken) {
          config.headers = assign({}, config.headers, {
            [BOGUS_TOKEN_NAME]: bogusToken,
          })
        }
      } catch (e) {
        // ignoring when token failed to retrieve token.
      }

      return config
    })

    // interceptors 先入後出
    // debugLog `DONE ${method} url=${url} data=${data}`
    // TODO: response = response.data
    this.axiosInstance.interceptors.response.use(
      (response) => {
        try {
          if (response.request.responseType !== 'arraybuffer') {
            response.data = humps.camelizeKeys(
              response.data,
              (key, convert, options) => {
                // We don't convert the key in 12_23 case.
                if (/\d_\d/g.test(key)) {
                  return key
                }
                switch (key) {
                  case 'ID':
                    return 'id'
                  case 'UUID':
                    return 'UUID'
                  case 'icon_uuid':
                    return 'iconUUID'
                  case 'kol_uuid':
                    return 'kolUUID'
                  case 'URL':
                    return 'url'
                  case 'PCU':
                    return 'pcu'
                  case 'SignedURL':
                    return 'signedUrl'
                  default:
                    return convert(key, options)
                }
              },
            )
          }

          return response
        } catch (error) {
          console.info('something wrong!')
        }

        return response
      },
      (error) => {
        // Reference to https://github.com/nuxt/nuxt.js/issues/5551
        // We need to trim the axios error object which we need.

        // `僅將 message 拉到 最外層

        const { response } = error
        if (response.status >= 500) {
          Sentry.captureException(error)
        }

        const message =
          response?.data?.msg ||
          response?.data?.message ||
          response?.data?.error?.message ||
          response?.statusText ||
          error?.message

        const trimedError = {
          ...error,
          message: isEmpty(message) ? '好像哪裡出錯了, 請再試一次.' : message,
        }

        return Promise.reject(trimedError)
      },
    )
  }
}
