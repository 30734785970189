import { FetchArgs } from '@reduxjs/toolkit/query'
import { assign, isString } from 'lodash-es'
import { ipService } from '@/api/utils/ip-service'
import { paramsSerializer } from '@/api/utils/params-serializer'
import Environment from '@/constants/environment'
import env from '@/utils/env'
import logger from '@/utils/logger'
import { BaseQuery } from '@/utils/redux-toolkit'

export const BOGUS_TOKEN_NAME = 'ikala-bogus-token'

async function generateBogusToken(message: string): Promise<string> {
  try {
    const { encrypt } = await import('@ikala/bogus')

    return encrypt(message, await ipService.getIp())
  } catch (e) {
    logger.error(e)
    return ''
  }
}

export const getBogusToken = async (
  url: string,
  params?: Record<string, unknown>,
  body?: Record<string, unknown> | Record<string, unknown>[],
): Promise<string | undefined> => {
  if (
    (!url.startsWith('/v2') && !url.startsWith('/v3')) ||
    env.ENVIRONMENT === Environment.Development
  ) {
    return undefined
  }

  const fullUrl = params ? `${url}?${paramsSerializer(params)}` : url

  return await generateBogusToken(
    `/api${fullUrl}|${navigator.userAgent}|${body ? JSON.stringify(body) : ''}`,
  )
}

export const bogusTokenInjector: (baseQuery: BaseQuery) => BaseQuery =
  (baseQuery: BaseQuery) => async (args, api, extraOptions) => {
    if (isString(args)) {
      const fetchArgs: FetchArgs = {
        url: args,
        headers: {
          [BOGUS_TOKEN_NAME]: await getBogusToken(args),
        },
      }

      return await baseQuery(fetchArgs, api, extraOptions)
    }

    args.headers = assign({}, args.headers, {
      [BOGUS_TOKEN_NAME]: await getBogusToken(args.url, args.params, args.body),
    })

    return await baseQuery(args, api, extraOptions)
  }

export default bogusTokenInjector
